import { useEffect, useState } from "react";
import { BsFillPlusCircleFill, BsSearch } from "react-icons/bs";
import Container from "../../components/Container";
import Header from "../../components/Header";
import {
  Stack,
  Box,
  InputGroup,
  InputLeftElement,
  Input,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  TableContainer,
  useColorModeValue,
  Flex,
  TabIndicator,
} from "@chakra-ui/react";
import { ActiveProducts } from "./components/ActiveProducts";
import { ArchivedProducts } from "./components/ArchivedProducts";
import { getArchievedProducts, getUserActiveProducts } from "./service";
import { useAuth } from "../../context/authContext";
import FullPageLoader from "../../components/FullPageLoader";
import Footer from "../../components/footer";
import { COLORS } from "../../constants/colors";
// import {
//   AddShopProduct,
//   AddShopProductModal,
// } from "./components/AddShopProductModal";
// import PostAdvert from "../post advert";
import { Link } from "react-router-dom";
import { addBtnStyles } from "../issue report";

const Shop = () => {
  const cardBg = useColorModeValue(COLORS.primary, COLORS.white);
  const tabColor = useColorModeValue(COLORS.primary, COLORS.green);
  const bgColor = useColorModeValue(COLORS.primary, COLORS.green);
  const appTextColor = useColorModeValue(COLORS.black, COLORS.white);

  const [inutValue, setInputValue] = useState("");
  const { userInfo } = useAuth();
  const { userId } = userInfo;
  const [loading, setLoading] = useState(false);
  const [archieveProducts, setArchievedProducts] = useState([]);
  const [userActiveproducts, setUserActiveproducts] = useState([]);

  const tabs = {
    borderRadius: "2px",
    transitionDuration: "500ms",
    fontWeight: 600,
    // color: {cardBg},
    // _selected: { color: { cardBg } },
    _focus: { border: "none" },
  };
  useEffect(() => {
    getArchievedProducts(userId, setLoading, setArchievedProducts);
    getUserActiveProducts(userId, setLoading, setUserActiveproducts);
  }, [userId]);

  const userProducts = userActiveproducts.filter(
    (prod) => prod.status !== "ARCHIVED"
  );

  console.log(userActiveproducts, "userActiveproducts", archieveProducts);
  return loading ? (
    <FullPageLoader />
  ) : (
    <Stack pb="15vh" position="relative">
      <Box shadow="sm">
        <Container>
          <Header menu />
        </Container>
      </Box>

      <Container>
        <InputGroup mt={"30px"}>
          <InputLeftElement fontSize="1.2em" children={<BsSearch />} />
          <Input
            value={inutValue}
            onChange={(e) => setInputValue(e.target.value)}
            border="none"
            borderRadius="30px !important"
            placeholder="Search Here"
            _placeholder={{ opacity: 1, color: `${appTextColor}` }}
            color={cardBg}
            bg={COLORS.lightBrown}
          />
        </InputGroup>
        <TableContainer py="50px">
          <Tabs variant="unstyled" isFitted>
            <TabList color={cardBg}>
              <Tab {...tabs}>Active Products</Tab>
              <Tab {...tabs}>Archived Products</Tab>
            </TabList>
            <TabIndicator
              mt="-1.5px"
              height="5px"
              bg={tabColor}
              borderTopRadius="5px"
            />
            <TabPanels mt={"20px"}>
              <TabPanel px="0">
                <ActiveProducts data={userProducts} />
              </TabPanel>
              <TabPanel px="0">
                <ArchivedProducts data={archieveProducts} />
              </TabPanel>
            </TabPanels>
          </Tabs>
          <Link to="/post-advert">
            <Flex {...addBtnStyles}>
              <BsFillPlusCircleFill color={bgColor} size={"2.5rem"} />
            </Flex>
          </Link>
        </TableContainer>
      </Container>
      <Footer />
    </Stack>
  );
};

export default Shop;
