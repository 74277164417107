import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Circle,
  FormControl,
  HStack,
  Img,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Stack,
  Text,
  Textarea,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import Container from "../../../components/Container";
import Header from "../../../components/Header";
import { inputBorder } from "../../post advert/components/data";
import naira from "../../../assets/naira.jpg";
import { PrimaryButton } from "../../../components/CustomButton";
import { Success } from "../../../components/Success";
import { useEffect, useState } from "react";
import { BsPlusLg } from "react-icons/bs";
import { useContext } from "react";
import { AuthContext } from "../../../context/authContext";
import { v4 } from "uuid";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { infoNotifier } from "../../../components/NotificationHandler";
import { editBeforeResell, getSingleArchieveProduct } from "../service";
import { getCategories } from "../../post advert/service";
import { UserProfile } from "../../../context/userProfileContext";
import { COLORS } from "../../../constants/colors";
import { BiCloudUpload } from "react-icons/bi";
import { PROTECTED_PATHS } from "../../../app/constants";
import { AiOutlineCloudUpload } from "react-icons/ai";

export const EditShopProduct = () => {
  // const { userInfo } = useContext(AuthContext);
  // const { userId, tenantCode, tenantPhone } = userInfo;
  const cardBg = useColorModeValue(COLORS.primary, COLORS.white);
  const bgColor = useColorModeValue(COLORS.primary, COLORS.green);
  const { userProfile } = UserProfile();
  const { _id, tenantCode, tenantPhone } = userProfile;
  const { shopProduct } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [success, setSuccess] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [adverts, setAdverts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState([]);
  const [userChangedImage, setUserChangedImage] = useState([]);
  const { SHOP } = PROTECTED_PATHS;

  const navigate = useNavigate();
  const serviceCharge = 200;
  let imgUrlsFromCloudinary = [];

  useEffect(() => {
    getCategories(_id, setLoading, setCategories);
  }, [_id]);

  useEffect(() => {
    getSingleArchieveProduct(_id, setLoading, setProduct, shopProduct);
  }, [_id, shopProduct]);

  useEffect(() => {
    setFormValues(product);
  }, [product]);

  useEffect(() => {
    setAdverts(formValues?.imageUrl);
  }, [formValues?.imageUrl]);

  const handleImageChange = (e) => {
    e.preventDefault();

    if (!e.target.files) return;

    if (Array(...e.target.files).length > 5) {
      infoNotifier("Pick a maximum of five Images");
      return;
    }

    if (e.target.files) {
      Array.from(e.target.files).forEach((file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = () => {
          // setAdverts((prev) => [...prev, { imageUrl: reader.result, file }]);
          setUserChangedImage((prev) => [
            ...prev,
            { imageUrl: reader.result, file },
          ]);
        };
      });
    }
  };

  const inputStyle = {
    bg: "white",
    rounded: "lg",
  };

  const handlePostAdvert = (evt, files) => {
    evt.preventDefault();
    setLoading(true);

    const uploaders = files.map((file) => {
      const formData = new FormData();

      formData.append("file", file.file);
      formData.append(
        "upload_preset",
        process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET
      );

      return axios
        .post(
          `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`,
          formData,
          {
            headers: { "X-Requested-With": "XMLHttpRequest" },
          }
        )
        .then((response) => {
          const data = response.data;
          const fileURL = data.secure_url;
          imgUrlsFromCloudinary.push(fileURL);
        });
    });

    axios.all(uploaders).then(() => {
      const { itemName, category, itemDescription, quantity, price, _id } =
        formValues;
      const payload = {
        userId: _id,
        tenantCode,
        price: `${Number(price) + serviceCharge}`,
        itemName,
        itemDescription,
        category: `${category.charAt(0).toUpperCase()}${category
          .slice(1)
          .toLowerCase()}`,
        quantity,
        imageUrl: imgUrlsFromCloudinary,
        phoneNumber: tenantPhone,
        advertId: _id,
      };

      editBeforeResell(payload, setLoading, navigate, setSuccess);
      console.log("promise.all");
    });
  };

  const editWithInitialImages = () => {
    const payload = {
      ...formValues,
      advertId: formValues._id,
      userId: _id,
      tenantCode,
    };

    editBeforeResell(payload, setLoading, navigate, setSuccess);
  };

  const onChange = (e) => {
    e.preventDefault();
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const handleOpen = () => {
    const { itemName, category, itemDescription, quantity, price } = formValues;
    if (!itemName || !category || !itemDescription || !quantity || !price) {
      infoNotifier("Please fill all fields");
      return;
    }
    if (adverts.length <= 0) {
      infoNotifier("Please add images for your Advert");
      return;
    }

    onOpen();
  };

  return success ? (
    <Success />
  ) : (
    <Stack>
      <Box shadow="sm">
        <Container>
          <Header goto={SHOP} />
        </Container>
      </Box>
      <Container>
        <FormControl py="15px">
          <Stack spacing="10px">
            <Input
              {...inputStyle}
              placeholder="Name of Item"
              name="itemName"
              defaultValue={formValues?.itemName}
              onChange={onChange}
            />
            <Select
              {...inputStyle}
              placeholder="Category"
              name="category"
              value={formValues?.category}
              onChange={onChange}
            >
              {categories?.map((categorie) => (
                <option key={v4()}>{categorie?.categoryName}</option>
              ))}
            </Select>
            <Select
              {...inputStyle}
              placeholder="Sub Category"
              name="subCategory"
              value={formValues?.category}
              onChange={onChange}
            >
              {categories?.map((categorie) => (
                <option key={v4()}>{categorie?.categoryName}</option>
              ))}
            </Select>
            <Text fontWeight="600" color={cardBg}>
              Add Photo
            </Text>
            <Text color={cardBg}>
              First photo is the title picture. You are to add at least two and
              maximum of 5 pictures
            </Text>
            <HStack>
              <label htmlFor="advert-picture">
                <HStack
                  cursor="pointer"
                  bg={COLORS.green}
                  p={"5px 10px"}
                  rounded={"lg"}
                >
                  <Text color={cardBg} fontWeight="500">
                    Upload media
                  </Text>
                  <AiOutlineCloudUpload size={"1.5rem"} color={cardBg} />
                </HStack>

                <Input
                  type="file"
                  id="advert-picture"
                  display="none"
                  multiple
                  w="120px !important"
                  onChange={handleImageChange}
                />
              </label>
              {!userChangedImage.length
                ? adverts?.map((ad) => (
                    <Img
                      mx="2"
                      w="80px"
                      h="80px"
                      objectFit="cover"
                      key={v4()}
                      src={ad || ad.imageUrl}
                      alt="image"
                    />
                  ))
                : userChangedImage?.map((newImgs) => (
                    <Img
                      mx="2"
                      w="80px"
                      h="80px"
                      objectFit="cover"
                      key={v4()}
                      src={newImgs.imageUrl}
                      alt="image"
                    />
                  ))}
            </HStack>
            <Textarea
              {...inputStyle}
              resize="none"
              rows="5"
              placeholder="Description"
              name="itemDescription"
              defaultValue={formValues?.itemDescription}
              onChange={onChange}
            />
          </Stack>
          <HStack spacing="2rem" py="5">
            <Input
              {...inputStyle}
              flex={1}
              onChange={onChange}
              placeholder="Quantity"
              name="quantity"
              type="number"
              defaultValue={formValues?.quantity}
            />
            <InputGroup flex={2}>
              <InputLeftAddon
                children={<Img w="30px" src={naira} alt="naira" />}
              />
              <Input
                {...inputStyle}
                type="number"
                placholder="price"
                name="price"
                onChange={onChange}
                defaultValue={formValues?.price}
              />
            </InputGroup>
          </HStack>
          <Input
            {...inputStyle}
            placeholder="Name of seller"
            name="name"
            onChange={onChange}
            defaultValue={formValues?.userId?.tenantName}
          />
          <HStack py="15px" justify="space-between" gap="1.5rem">
            <Stack
              {...inputStyle}
              textAlign="center"
              bg="#c7ebe2"
              px="15px"
              w="150px"
            >
              <Text as="small">Service Charge</Text>
              <Text fontSize="0.9rem" fontWeight="700" m="0 !important">
                &#8358;{serviceCharge}
              </Text>
            </Stack>
            <Box w={["180px", "200px"]} py="15px">
              <PrimaryButton
                size="md"
                isLoading={loading}
                btnText="Post Advert"
                onClick={handleOpen}
              />
            </Box>
          </HStack>
        </FormControl>
      </Container>

      <AlertDialog
        size="xs"
        motionPreset="slideInRight"
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent borderRadius="2px">
          <AlertDialogHeader>Service Charge</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Please note there will be a &#8358;
            <Text fontWeight="600" fontSize="1.2rem " as="span">
              {serviceCharge}
            </Text>{" "}
            service charge to post this product in the market place
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button onClick={onClose}>No</Button>
            <Button
              bg="primary"
              color={COLORS.white}
              w="80px"
              _hover={{ bg: "primaryDark" }}
              ml={5}
              isLoading={loading}
              onClick={
                userChangedImage.length
                  ? (e) => handlePostAdvert(e, userChangedImage)
                  : editWithInitialImages
              }
            >
              Yes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Stack>
  );
};
