import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
  Button,
  Flex,
  Text,
  useColorModeValue,
  Heading,
  Stack,
  Box,
  HStack,
} from "@chakra-ui/react";
import { COLORS } from "../../../constants/colors";
import DatePicker from "react-multi-date-picker";
import dayjs from "dayjs";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PROTECTED_PATHS } from "../../../app/constants";
import Container from "../../../components/Container";
import { accountStatement } from "../service";
import { errorNotifier } from "../../../components/NotificationHandler";

export const AccountStatementModal = ({ userId, setLoading }) => {
  const today = new Date();
  const { ACCOUNT_STATEMENT } = PROTECTED_PATHS;
  const bgColor = useColorModeValue(COLORS.primary, COLORS.green);
  const cardBg = useColorModeValue(COLORS.lightGrey, COLORS.white);
  const textColor = useColorModeValue(COLORS.primary, COLORS.white);
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const navigate = useNavigate();

  const initialValues = {
    userId: userId,
    startDate,
    endDate,
    // endDate: dayjs(today).format("YYYY-MM-DD"),
  };
  const [values, setValues] = useState(initialValues);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleStartDate = (date) => {
    setStartDate(date);
    const formated = dayjs(date).format("YYYY-MM-DD");
    const formatedDate = formated;

    setValues((values) => ({
      ...values,
      startDate: formatedDate,
    }));
  };
  const handleEndDate = (date) => {
    setEndDate(date);
    const formated = dayjs(date).format("YYYY-MM-DD");
    const formatedDate = formated;

    setValues((values) => ({
      ...values,
      endDate: formatedDate,
    }));
  };

  const isContinueDisabled = !startDate || !endDate;

  const handleContinue = () => {
    if (isContinueDisabled) {
      alert("Please choose a start and end date");
    } else {
      handleSubmit();
    }
  };
  const handleSubmit = async () => {
    const payload = {
      userId: userId,
      startDate: values.startDate,
      endDate: values.endDate,
    };

    try {
      const result = await accountStatement(payload, setLoading);
      console.log("Account Statement Result:", result);

      if (result.result.success && result.result.data.length > 0) {
        navigate(ACCOUNT_STATEMENT, {
          state: {
            data: result.result.data,
            startDate: values.startDate,
            endDate: values.endDate,
          },
        });
      } else {
        console.log("No transactions found.");
        errorNotifier("No transactions found for the specified date range");
      }
    } catch (error) {
      console.error("Error fetching account statement:", error);
      errorNotifier("An error occurred while fetching account statement");
    }
  };

  return (
    <>
      <Flex justify="flex-end" w="100%" onClick={onOpen} cursor="pointer">
        <Text color={bgColor}>Get Account Statement</Text>
      </Flex>
      <Container>
        <Modal size="sm" isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent py="30px">
            <ModalBody>
              <Heading fontSize="20px" color={textColor} textAlign={"center"}>
                Get Account Statement
              </Heading>
              <Text
                color={textColor}
                pb="50px"
                pt={"40px"}
                fontSize="18px"
                textAlign={"center"}
              >
                Click on the input field to choose date
              </Text>
              <HStack spacing={4}>
                <Box w={"100%"} cursor={"pointer"}>
                  <Text color={textColor}>Start Date</Text>
                  <DatePicker
                    minDate={""}
                    placeholder={new Date()}
                    dateFormat={"dd/MM/yyyy"}
                    selected={startDate}
                    onChange={(date) => handleStartDate(date)}
                    style={{
                      color: "green",
                      height: "50px",
                      width: "100%",
                      borderColor: "green",
                    }}
                  />
                </Box>
                <Box w={"100%"} cursor={"pointer"}>
                  <Text color={textColor}>End Date</Text>
                  <DatePicker
                    minDate={""}
                    placeholder={new Date()}
                    dateFormat={"dd/MM/yyyy"}
                    selected={endDate}
                    onChange={(date) => handleEndDate(date)}
                    style={{
                      height: "50px",
                      width: "100%",
                      borderColor: "green",
                    }}
                  />
                </Box>
              </HStack>
              <Button
                color={cardBg}
                bg={bgColor}
                w={"100%"}
                my={"50px !important"}
                _hover={{ backgroundColor: `${bgColor}` }}
                onClick={handleContinue}
                disabled={isContinueDisabled}
              >
                Continue
              </Button>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Container>
    </>
  );
};

// import {
//   Modal,
//   ModalOverlay,
//   ModalContent,
//   ModalBody,
//   useDisclosure,
//   Button,
//   Flex,
//   Text,
//   useColorModeValue,
//   Heading,
//   Stack,
//   Box,
//   HStack,
// } from "@chakra-ui/react";
// import { COLORS } from "../../../constants/colors";
// import DatePicker from "react-multi-date-picker";
// import dayjs from "dayjs";
// import { useState } from "react";
// import { UserProfile } from "../../../context/userProfileContext";
// import { useNavigate } from "react-router-dom";
// import { PROTECTED_PATHS } from "../../../app/constants";
// import Container from "../../../components/Container";
// import { accountStatement } from "../service";

// export const AccountStatementModal = ({ data, userId, setLoading }) => {
//   const today = new Date();
//   const { ACCOUNT_STATEMENT } = PROTECTED_PATHS;
//   const bgColor = useColorModeValue(COLORS.primary, COLORS.green);
//   const appTextColor = useColorModeValue(COLORS.primary, COLORS.white);
//   const iconColor = useColorModeValue(COLORS.white, COLORS.primary);
//   const cardBg = useColorModeValue(COLORS.lightGrey, COLORS.white);
//   const textColor = useColorModeValue(COLORS.primary, COLORS.white);
//   const [startDate, setStartDate] = useState(today);
//   const [endDate, setEndDate] = useState(today);
//   const { userProfile } = UserProfile();
//   const { _id, tenantCode } = userProfile;
//   const navigate = useNavigate();

//   const initialValues = {
//     tenantCode,
//     userId: _id,
//     startDate: dayjs(today).format("YYYY-MM-DD"),
//     endDate: dayjs(today).format("YYYY-MM-DD"),
//     // days: [],
//   };
//   const [values, setValues] = useState(initialValues);

//   const { isOpen, onOpen, onClose } = useDisclosure();
//   const handleStartDate = (date) => {
//     setStartDate(date);
//     const formated = dayjs(date).format("YYYY-MM-DD");
//     const formatedDate = formated;

//     setValues((values) => ({
//       ...values,
//       startDate: formatedDate,
//     }));
//   };
//   const handleEndDate = (date) => {
//     setEndDate(date);
//     const formated = dayjs(date).format("YYYY-MM-DD");
//     const formatedDate = formated;

//     setValues((values) => ({
//       ...values,
//       endDate: formatedDate,
//     }));
//   };

//   const isContinueDisabled = !startDate || !endDate;

//   const handleContinue = () => {
//     if (isContinueDisabled) {
//       alert("Please choose a start and end date");
//     } else {
//       navigate(ACCOUNT_STATEMENT);
//     }
//   };

//   const handleSubmit = () => {
//     const payload = {
//       userId: userId,
//       startDate,
//       endDate,
//     };
//     accountStatement(payload, setLoading);
//   };

//   return (
//     <>
//       <Flex justify="flex-end" w="100%" onClick={onOpen} cursor="pointer">
//         <Text color={bgColor}>Get Account Statement</Text>
//       </Flex>
//       <Container>
//         <Modal size="sm" isOpen={isOpen} onClose={onClose}>
//           <ModalOverlay />
//           <ModalContent py="30px">
//             <ModalBody>
//               <Heading fontSize="20px" color={textColor} textAlign={"center"}>
//                 Get Account Statement
//               </Heading>
//               <Text
//                 color={textColor}
//                 pb="50px"
//                 pt={"40px"}
//                 fontSize="18px"
//                 textAlign={"center"}
//               >
//                 Click on the input field to choose date
//               </Text>
//               <HStack spacing={4}>
//                 <Box w={"100%"} cursor={"pointer"}>
//                   <Text color={textColor}>Start Date</Text>
//                   <DatePicker
//                     minDate={new Date()}
//                     placeholder={new Date()}
//                     dateFormat={"dd/MM/yyyy"}
//                     selected={startDate}
//                     onChange={(date) => handleStartDate(date)}
//                     style={{
//                       color: "green",
//                       height: "50px",
//                       width: "100%",
//                       borderColor: "green",
//                     }}
//                   />
//                 </Box>
//                 <Box w={"100%"} cursor={"pointer"}>
//                   <Text color={textColor}>End Date</Text>
//                   <DatePicker
//                     minDate={new Date()}
//                     placeholder={new Date()}
//                     dateFormat={"dd/MM/yyyy"}
//                     selected={endDate}
//                     onChange={(date) => handleEndDate(date)}
//                     style={{
//                       height: "50px",
//                       width: "100%",
//                       borderColor: "green",
//                     }}
//                   />
//                 </Box>
//               </HStack>
//               <Button
//                 color={cardBg}
//                 bg={bgColor}
//                 w={"100%"}
//                 my={"50px !important"}
//                 _hover={{ backgroundColor: `${bgColor}` }}
//                 onClick={handleContinue}
//                 disabled={isContinueDisabled}
//               >
//                 Continue
//               </Button>
//             </ModalBody>
//           </ModalContent>
//         </Modal>
//       </Container>
//     </>
//   );
// };
