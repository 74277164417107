// import {
//   Box,
//   Img,
//   Stack,
//   Text,
//   HStack,
//   useColorModeValue,
// } from "@chakra-ui/react";
// import { useContext, useEffect, useState } from "react";
// import { v4 } from "uuid";
// import { PROTECTED_PATHS } from "../../../app/constants";
// import Container from "../../../components/Container";
// import FullPageLoader from "../../../components/FullPageLoader";
// import Header from "../../../components/Header";
// import { AuthContext } from "../../../context/authContext";
// import SearchBar from "./SearchBar";
// import { useNavigate } from "react-router-dom";
// import {
//   getCategories,
//   getCategoryList,
//   getAdvertProductsByCategory,
// } from "../service";
// import { COLORS } from "../../../constants/colors";

// const SameCategory = () => {
//   const cardBg = useColorModeValue(COLORS.primary, COLORS.white);
//   const { userInfo } = useContext(AuthContext);
//   const { userId } = userInfo;
//   const [loading, setLoading] = useState(false);
//   const { MARKET_PLACE, SPECIFIC_PRODUCT, ALL_CATEGORY } = PROTECTED_PATHS;
//   const [input, setInput] = useState("");
//   const [categories, setCategories] = useState([]);
//   const [categoryList, setCategoryList] = useState([]);
//   const navigate = useNavigate();
//   // const parentPath = dynamicPathhandler(CATEGORY);

//   useEffect(() => {
//     getCategories(userId, setLoading, setCategories);
//     getCategoryList(userId, setLoading, setCategoryList);
//   }, [userId]);
//   console.log("categoryList:", categoryList);

//   return loading ? (
//     <FullPageLoader />
//   ) : (
//     <Stack>
//       <Box shadow="sm">
//         <Container>
//           <Header goto={ALL_CATEGORY} />
//         </Container>
//       </Box>

//       <Container>
//         <Box py="5" mb="50px">
//           <SearchBar input={input} setInput={setInput} />
//         </Box>
//         {categories?.map((category, index) => (
//           <>
//             <HStack
//               shadow="md"
//               p={"5px"}
//               cursor="pointer"
//               w="100%"
//               py="10px"
//               borderBottom="1px solid grey"
//               key={index}
//               onClick={() => navigate(SPECIFIC_PRODUCT)}
//             >
//               <Img
//                 w={["30px"]}
//                 maxW="30px"
//                 h={["30px"]}
//                 objectFit="cover"
//                 rounded="lg"
//                 src={category?.image}
//                 alt={category?.categoryName}
//               />

//               <Text
//                 textAlign="center"
//                 fontSize="15px"
//                 fontWeight="600"
//                 color={cardBg}
//               >
//                 {category?.categoryName}
//               </Text>
//             </HStack>
//           </>
//         ))}
//       </Container>
//     </Stack>
//   );
// };

// export default SameCategory;

import {
  Box,
  Checkbox,
  HStack,
  Img,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Container from "../../../components/Container";
import Footer from "../../../components/footer";
import FullPageLoader from "../../../components/FullPageLoader";
import Header from "../../../components/Header";
import { AuthContext } from "../../../context/authContext";
import {
  getCategories,
  getCategoryList,
  getSearchAdvertProductsInCategory,
} from "../service";
import OfferWithPrice from "./OfferWithPrice";
import { v4 } from "uuid";
import { BsSearch } from "react-icons/bs";
import filterIcon from "../../../assets/Layer 32.svg";
import useDebounce from "../../../components/hooks/useDebounce";
import NotFound from "./NotFound";
import { PROTECTED_PATHS } from "../../../app/constants";

const SameCategory = () => {
  const { MARKET_PLACE } = PROTECTED_PATHS;
  const { userInfo } = useContext(AuthContext);
  const { userId } = userInfo;
  const [loading, setLoading] = useState(false);
  const [allAdvertsInCategory, setAllAdvertsInCategory] = useState([]);
  const [searchKey, setSearchKey] = useState("itemName");
  const [searchValue, setSearchValue] = useState("");
  const [category, setCategory] = useState([]);

  const debouncedSearch = useDebounce(searchValue, 1000);

  console.log("category:", category);

  // SameCategory.jsx
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        // Pass setLoading to getCategories function
        const categories = await getCategories(userId, setLoading, setCategory);
        if (categories && categories.length > 0) {
          setCategory(categories);
        } else {
          console.error("No categories fetched.");
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, [userId]);

  useEffect(() => {
    if (category?.length < 0) return;

    const categoryNames = category[0]?.categoryName?.toLowerCase(); // Extract category names
    getCategoryList(userId, categoryNames, setLoading, setAllAdvertsInCategory); // Pass category names to the API
  }, [userId, category]);

  useEffect(() => {
    if (debouncedSearch && category.length > 0) {
      getSearchAdvertProductsInCategory(
        userId,
        category[0], // Assuming you want to use the first category
        searchKey,
        debouncedSearch,
        setAllAdvertsInCategory
      );
    }
  }, [userId, category, searchKey, debouncedSearch]);

  return (
    <>
      {loading && !allAdvertsInCategory?.length && <FullPageLoader />}

      {!loading && !allAdvertsInCategory?.length && <NotFound />}

      {!loading && allAdvertsInCategory?.length > 0 && (
        <Stack>
          <Box shadow="sm">
            <Container>
              <Header goto={MARKET_PLACE} />
            </Container>
          </Box>

          <Container>
            <Stack spacing={4}>
              <InputGroup rounded="lg" border="1px solid #FBD5D3">
                <InputLeftElement fontSize="1.2em" children={<BsSearch />} />
                <Input
                  type={searchKey === "price" ? "number" : "text"}
                  border="none"
                  placeholder={
                    searchKey === "itemName"
                      ? "search by name"
                      : "search by price"
                  }
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <InputRightElement>
                  <Menu closeOnSelect={false}>
                    <MenuButton cursor="pointer" as={Box}>
                      <Img src={filterIcon} alt="filter" fontSize="1em" />
                    </MenuButton>
                    <MenuList w="100px" bg="gray.50">
                      <HStack>
                        <Text pb="2" pl="5%" fontWeight="600">
                          Search by:
                        </Text>
                      </HStack>
                      <MenuItem>
                        <Checkbox
                          isChecked={searchKey === "itemName"}
                          colorScheme={"green"}
                          onChange={() => {
                            setSearchKey("itemName");
                            setSearchValue("");
                          }}
                        >
                          Name
                        </Checkbox>
                      </MenuItem>
                      <MenuItem>
                        <Checkbox
                          isChecked={searchKey === "price"}
                          colorScheme={"green"}
                          onChange={() => {
                            setSearchKey("price");
                            setSearchValue("");
                          }}
                        >
                          Price
                        </Checkbox>
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </InputRightElement>
              </InputGroup>
            </Stack>
            <Stack>
              {allAdvertsInCategory?.map((advertInCategory) => (
                <OfferWithPrice key={v4()} advert={advertInCategory} />
              ))}
            </Stack>
          </Container>
          <Footer />
        </Stack>
      )}
    </>
  );
};

export default SameCategory;
