import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import AuthContextProvider from "./context/authContext";

export const transition = { enter: { duration: 0.5 }, exit: { duration: 0.1 } };

const theme = extendTheme({
  colors: {
    //AAHERA COLOrs
    // primary: "rgb(7,47,100)",
    primary: "#1D8C23",
    primaryDark: "#1D8C23",
    // primaryDark: "rgb(3, 23, 48)",
    primaryLight: "rgba(7, 47, 103, 0.8)",
    secondary: "#FC7067",
    bgColor: "#EFE2FF",
    bgColorLight: "#F9F9F9",
    lightPink: "#FEE8E7",
  },

  styles: {
    global: {
      body: {
        fontFamily: "Raleway, sans-serif",
        fontSize: "14px",
        color: "#1D8C23",
        // color: "rgb(7,47,100)",
        fontWeight: 500,
      },
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthContextProvider>
        <ChakraProvider theme={theme}>
          <App />
        </ChakraProvider>
      </AuthContextProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// ServiceWorkerRegistration.register();
