import {
  Box,
  Button,
  HStack,
  Img,
  Square,
  Flex,
  Text,
  useColorModeValue,
  Link,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import Container from "../../../components/Container";
import Header from "../../../components/Header";
import car from "../../../assets/car.png";
import Footer from "../../../components/footer";
import { Success } from "../../../components/Success";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../context/authContext";
import { getSingleAdvertProduct } from "../service/topProducts";
import FullPageLoader from "../../../components/FullPageLoader";
import { v4 } from "uuid";
import { PROTECTED_PATHS } from "../../../app/constants";
import CustomModal from "../../../components/CustomModal";
import SendMessage from "./SendMessage";
import { COLORS } from "../../../constants/colors";

const TopProductInfo = () => {
  const cardBg = useColorModeValue(COLORS.primary, COLORS.white);
  const { productId } = useParams();
  const [success] = useState(false);
  const { userInfo } = useContext(AuthContext);
  const { userId } = userInfo;
  const [singleTopProductDetails, setSingleTopProductDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const { MARKET_PLACE } = PROTECTED_PATHS;

  useEffect(() => {
    getSingleAdvertProduct(
      userId,
      productId,
      setLoading,
      setSingleTopProductDetails
    );
  }, [userId, productId]);

  return (
    <>
      {!success && loading && <FullPageLoader />}

      {success && !loading && <Success />}

      {!loading && !success && (
        <Box pb="15vh">
          <Box shadow="sm">
            <Container>
              <Header goto={MARKET_PLACE} />
            </Container>
          </Box>
          <Container>
            <Box>
              <Text color={cardBg} fontSize="20px" pt={"10px"}>
                {singleTopProductDetails?.itemName}
              </Text>
              <>
                <Img
                  w={"100%"}
                  h="230px"
                  // objectFit={"cover"}
                  rounded="2xl"
                  pt="10px"
                  src={
                    singleTopProductDetails?.imageUrl?.length > 0
                      ? singleTopProductDetails?.imageUrl[0]
                      : car
                  }
                  alt={singleTopProductDetails?.itemName || "car"}
                />
                <Box bg="#D9D9D9" rounded="2xl" p="15px" mt="20px">
                  {singleTopProductDetails?.imageUrl?.length &&
                    singleTopProductDetails?.imageUrl
                      ?.slice(0, 1)
                      ?.map((topProd) => (
                        <Img
                          w={"100px"}
                          h={"100px"}
                          rounded="2xl"
                          src={topProd ? topProd : car}
                          alt={"product"}
                        />
                      ))}
                </Box>
                <Box bg="#fff" rounded="2xl" p="20px" mt="20px">
                  <Flex justify="space-between">
                    <Text color="gray">Price</Text>
                    <Text color={COLORS.green} fontWeight="semibold">
                      {singleTopProductDetails?.price?.toLocaleString()} NGN
                    </Text>
                  </Flex>
                  <Flex justify="space-between" py="15px">
                    <Text color="gray">Quantity</Text>
                    <Text fontWeight="semibold">
                      {singleTopProductDetails?.quantity}
                    </Text>
                  </Flex>
                  <Text color="gray">Description</Text>
                  <Text pt="10px">
                    {singleTopProductDetails?.itemDescription}
                  </Text>
                </Box>
                <Flex
                  justify="space-between"
                  gap={"20px"}
                  mt="30px"
                >
                  <CustomModal
                    buttonProps={{
                      border: "1px solid green",
                      size: "lg",
                      // bg: "#f7f7ff",
                      color: "primary",
                      w: "50%",
                    }}
                    btnTitle="Send Message"
                  >
                    <SendMessage
                      singleTopProductDetails={singleTopProductDetails}
                    />
                  </CustomModal>
                  <Link
                    w="50%"
                    h="100%"
                    href={`tel:${singleTopProductDetails?.phoneNumber}`}
                  >
                    <Button
                      w="100%"
                      align="center"
                      p="0"
                      size="lg"
                      bg="primary"
                      _hover={{ bg: "primary" }}
                      color="#fff"
                    >
                      <Text>Call now</Text>
                    </Button>
                  </Link>
                </Flex>
              </>
            </Box>
          </Container>
          <Footer />
        </Box>
      )}
    </>
  );
};

export default TopProductInfo;
// import {
//   Box,
//   Button,
//   HStack,
//   Img,
//   Square,
//   Stack,
//   Text,
//   useColorModeValue,
//   Link,
// } from "@chakra-ui/react";
// import React, { useContext, useEffect, useState } from 'react';
// import Container from '../../../components/Container';
// import Header from '../../../components/Header';
// import car from '../../../assets/car.png';
// import Footer from '../../../components/footer';
// import { Success } from '../../../components/Success';
// import { useParams } from 'react-router-dom';
// import { AuthContext } from '../../../context/authContext';
// import { getSingleAdvertProduct } from '../service/topProducts';
// import FullPageLoader from '../../../components/FullPageLoader';
// import { v4 } from 'uuid';
// import { PROTECTED_PATHS } from '../../../app/constants';
// import CustomModal from '../../../components/CustomModal';
// import SendMessage from './SendMessage';
// import { COLORS } from "../../../constants/colors";

// const TopProductInfo = () => {
//     const cardBg = useColorModeValue(COLORS.primary, COLORS.white);
//   const { productId } = useParams();
//   const [success] = useState(false);
//   const { userInfo } = useContext(AuthContext);
//   const { userId } = userInfo;
//   const [singleTopProductDetails, setSingleTopProductDetails] = useState({});
//   const [loading, setLoading] = useState(false);
//   const { MARKET_PLACE } = PROTECTED_PATHS;

//   useEffect(() => {
//     getSingleAdvertProduct(
//       userId,
//       productId,
//       setLoading,
//       setSingleTopProductDetails
//     );
//   }, [userId, productId]);

//   return (
//     <>
//       {!success && loading && <FullPageLoader />}

//       {success && !loading && <Success />}

//       {!loading && !success && (
//         <Box>
//           <Box shadow="sm">
//             <Container>
//               <Header goto={MARKET_PLACE} />
//             </Container>
//           </Box>
//           <Container>
//             <Box py="15px">
//               <Text fontWeight="900" fontSize="24px">
//                 {singleTopProductDetails?.itemName}
//               </Text>
//               <Img
//                 borderRadius="10px"
//                 w="100%"
//                 objectFit="cover"
//                 shadow="lg"
//                 h="250px"
//                 src={
//                   singleTopProductDetails?.imageUrl?.length > 0
//                     ? singleTopProductDetails?.imageUrl[0]
//                     : car
//                 }
//                 alt={singleTopProductDetails?.itemName || "car"}
//               />
//             </Box>
//             <HStack gap="0.8rem" wrap="wrap">
//               {singleTopProductDetails?.imageUrl?.length &&
//                 singleTopProductDetails?.imageUrl
//                   ?.slice(1)
//                   ?.map((topProd) => (
//                     <Img
//                       key={v4()}
//                       w="100px"
//                       h="100px"
//                       objectFit="cover"
//                       shadow="lg"
//                       borderRadius="5px"
//                       src={topProd ? topProd : car}
//                       alt="car"
//                     />
//                   ))}
//             </HStack>
//             <Stack color="#7A7A7A" my="5">
//               <Text fontWeight="900" fontSize="1.4rem">
//                 {singleTopProductDetails?.itemName}
//               </Text>
//               <Text>{singleTopProductDetails?.itemDescription}</Text>
//               <HStack justify="space-between">
//                 <Text fontSize="1.5rem" fontWeight="900">
//                   &#8358; {singleTopProductDetails?.price}
//                 </Text>
//                 <Square py="1" px="2">
//                   <Text pr="2" fontSize="1rem" fontWeight="800">
//                     Qty
//                   </Text>
//                   <Text
//                     p="2"
//                     borderRadius="5px"
//                     bg="#FEE4B8"
//                     fontWeight="bold"
//                     fontSize="1rem"
//                   >
//                     {singleTopProductDetails?.quantity}
//                   </Text>
//                 </Square>
//               </HStack>
//             </Stack>
//             <HStack pb="20" justify="space-between" gap="1rem">
//               <CustomModal
//                 buttonProps={{
//                   border: "1px solid #FEE4B8",
//                   bg: "#f7f7ff",
//                   color: "primary",
//                   w: "50%",
//                 }}
//                 btnTitle="Send Message"
//               >
//                 <SendMessage
//                   singleTopProductDetails={singleTopProductDetails}
//                 />
//               </CustomModal>

//               <Link
//                 w="50%"
//                 h="100%"
//                 href={`tel:${singleTopProductDetails?.phoneNumber}`}
//               >
//                 <Button
//                   w="100%"
//                   align="center"
//                   p="0"
//                   bg="primary"
//                   _hover={{ bg: "primary" }}
//                   color="#fff"
//                 >
//                   <Text>Call now</Text>
//                 </Button>
//               </Link>
//             </HStack>
//           </Container>
//           <Footer />
//         </Box>
//       )}
//     </>
//   );
// };

// export default TopProductInfo;
