import {
  Box,
  Center,
  Flex,
  Image,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { COLORS } from "../../../constants/colors";
import dayjs from "dayjs";

export const Transactions = ({ data }) => {
  console.log(data, "trans");
  const cardBg = useColorModeValue(COLORS.lightGrey, COLORS.white);
  const transBg = useColorModeValue(COLORS.green, COLORS.chartbg);
  const bgColor = useColorModeValue(COLORS.primary, COLORS.green);
  return (
    <Box bg={transBg} p="15px" rounded={"lg"}>
      {data && data.length > 0 ? (
        data.slice(0, 3).map((datum) => (
          <>
            <Flex justify="space-between" mt="10px">
              <Text color={cardBg}>{datum?.services}</Text>
              <Text color={bgColor}>{datum?.status}</Text>
            </Flex>
            <Flex justify={"space-between"}>
              <Text color={COLORS.lightGrey}>
                {dayjs(datum?.updatedAt).format(" h:mm A MMM D, YYYY")}
              </Text>
              <Text color={cardBg}> NGN {datum?.amount}</Text>
            </Flex>
          </>
        ))
      ) : (
        <Center>
          <VStack>
            <Text as="h2" py="5" fontSize="18px" color={cardBg}>
              You have no transactions yet
            </Text>
          </VStack>
        </Center>
      )}
    </Box>
  );
};
