import { Box, Flex, Grid, Img, Text } from "@chakra-ui/react";
import { COLORS } from "../../../constants/colors";

const SplashScreen = () => {
  return (
    <Grid w="100vw" h="100vh" placeContent="center">
      {/*<Img src='/adesanya.png' alt='Abraham Adesanya' />*/}
      <Box bg="white" rounded="full" h="200px" w="200px">
        <Flex w="100%" justify="center" align="center">
          <Text fontSize="35px" fontWeight="bold" color={COLORS.green} mt="70px">
            Welcome
          </Text>
        </Flex>
      </Box>
    </Grid>
  );
};

export default SplashScreen;
