import {
  Box,
  Center,
  Circle,
  Image,
  Text,
  VStack,
  SlideFade,
  Flex,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { v4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { COLORS } from "../../constants/colors";
import { AuthContext } from "../../context/authContext";
import Footer from "../../components/footer";
import FullPageLoader from "../../components/FullPageLoader";
import Container from "../../components/Container";
import Header from "../../components/Header";
import { PROTECTED_PATHS } from "../../app/constants";
import { getIssueReports } from "./services/getIssueReports";
import { useEffect } from "react";
import IssueCard from "./components/IssueCard";

const IssueReportsHistory = () => {
  const bgColor = useColorModeValue(COLORS.primary, COLORS.green);
  const cardBg = useColorModeValue(COLORS.lightGrey, COLORS.white);

  const [issueReports, setIssueReports] = useState([]);
  const [loading, setLoading] = useState(false);

  const {
    userInfo: { userId },
  } = useContext(AuthContext);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    getIssueReports(userId, setIssueReports, setLoading);
  }, [userId, refresh]);

  const { ISSUE_REPORTS_HISTORY } = PROTECTED_PATHS;
  const navigate = useNavigate();

  return loading ? (
    <FullPageLoader />
  ) : (
    <SlideFade in={true} offsetX="2000px">
      <Box pos="relative" overflowY={"scroll"}>
        <Box shadow="sm">
          <Container>
            <Header menu />
          </Container>
        </Box>
        <Container>
          {issueReports.length > 0 &&
            issueReports?.map((issue) => (
              <IssueCard
                issue={issue}
                key={v4()}
                setRefresh={() => setRefresh(!refresh)}
              />
            ))}

          {!issueReports ||
            (!issueReports.length && (
              <Center h="calc(100vh - 150px)">
                <VStack>
                  <Image src="/Empty.svg" alt="Empty" />
                  <Text as="h2" py="5" fontSize="18px" color={cardBg}>
                    You have no reported Issue
                  </Text>
                </VStack>
              </Center>
            ))}
          <Flex {...addBtnStyles}>
            <BsFillPlusCircleFill
              color={bgColor}
              size={"2.5rem"}
              onClick={() => navigate(ISSUE_REPORTS_HISTORY)}
            />
          </Flex>
        </Container>
        <Footer />
      </Box>
    </SlideFade>
  );
};

export default IssueReportsHistory;

export const addBtnStyles = {
  justify: "flex-end",
  marginTop: "20px",
  cursor: "pointer",
  position: "fixed",
  bottom: "100px",
  right: "20px",
  width: "100%",
};

// export default IssueReport;
// import {
//   Box,
//   Flex,
//   FormControl,
//   FormLabel,
//   Img,
//   Input,
//   Select,
//   Stack,
//   Text,
//   Textarea,
//   SlideFade,
//   InputGroup,
//   InputLeftElement,
//   useColorModeValue,
//   HStack,
// } from "@chakra-ui/react";
// import React, { useState } from "react";
// import Container from "../../components/Container";
// import Header from "../../components/Header";
// import { formLabelStyles, issueTypes } from "./components/issueReportData";
// import { RiCameraLensLine } from "react-icons/ri";
// import { PrimaryButton } from "../../components/CustomButton";
// import OneIssue from "./components/OneIssue";
// import { infoNotifier } from "../../components/NotificationHandler";
// import Footer from "../../components/footer";
// import { PROTECTED_PATHS } from "../../app/constants";
// import { Link } from "react-router-dom";
// import { BsSearch } from "react-icons/bs";
// import { COLORS } from "../../constants/colors";
// import { AiOutlineCloudUpload } from "react-icons/ai";

// const IssueReport = () => {
//   const cardBg = useColorModeValue(COLORS.lightGrey, COLORS.white);
//   const bgColor = useColorModeValue(COLORS.primary, COLORS.green);
//   const appTextColor = useColorModeValue(COLORS.primary, COLORS.primary);
//   const [issueType, setIssueType] = useState("");
//   const [title, setTitle] = useState("");
//   const [description, setDescription] = useState("");
//   const [media, setMedia] = useState([]);
//   const [previewImg] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [displayOneIssue, setDisplayOneIssue] = useState(false);
//   const { ISSUE_REPORTS_HISTORY } = PROTECTED_PATHS;

//   const inputStyle = {
//     bg: "white",
//     rounded: "lg",
//     color: "#262636",
//   };

//   const handleImage = (event) => {
//     if (!event.target.files) return;

//     if (Array(...event.target.files).length > 5) {
//       infoNotifier("Pick a maximum of Three Images");
//       return;
//     }
//     const file = event.target.files[0];
//     const reader = new FileReader();
//     reader.readAsDataURL(file);

//     reader.onloadend = () => {
//       setMedia((prev) => [
//         ...prev,
//         { imgUrl: reader.result, file: event.target.files[0] },
//       ]);
//     };
//   };

//   const handleOneIssueDisplay = () => {
//     if (issueType && title && description) {
//       setDisplayOneIssue(true);
//       return;
//     }
//     infoNotifier("Please Fill in the Details");
//   };

//   return !displayOneIssue ? (
//     <SlideFade in={true} offsetX="-2000px">
//       <Box minH="100vh">
//         <Box shadow="sm">
//           <Container>
//             <Header menu />
//           </Container>
//         </Box>

//         <Container minH="calc(100vh - 100px)">
//           <FormControl h="100%">
//             <Stack py="15px" spacing="30px">
//               <Select
//                 {...inputStyle}
//                 placeholder="Report Type"
//                 _focus={{ border: "1px solid #F9C8C5" }}
//                 value={issueType}
//                 onChange={(e) => setIssueType(e.target.value)}
//               >
//                 {issueTypes?.map((type, index) => (
//                   <option key={index} value={type}>
//                     {type}
//                   </option>
//                 ))}
//               </Select>

//               <Input
//                 {...inputStyle}
//                 placeholder="Title"
//                 border="none"
//                 borderBottom="1px solid #ccc"
//                 borderRadius="0"
//                 value={title}
//                 onChange={(e) => setTitle(e.target.value)}
//               />
//               <Textarea
//                 {...inputStyle}
//                 placeholder="Details..."
//                 resize="none"
//                 rows="6"
//                 value={description}
//                 onChange={(e) => setDescription(e.target.value)}
//               />
//             </Stack>

//             <Flex mt="5" gap="10px">
//               <Box>
//                 <FormLabel {...formLabelStyles}>
//                   <HStack>
//                     <Text color={cardBg} fontWeight="500">
//                       Upload media
//                     </Text>
//                     <AiOutlineCloudUpload size={"1.5rem"} color={cardBg} />
//                   </HStack>
//                   <Input
//                     type="file"
//                     display="none"
//                     id="media"
//                     onChange={handleImage}
//                   />
//                 </FormLabel>
//               </Box>
//               <Flex wrap={"wrap"}>
//                 {media?.map((medium, i) => {
//                   return (
//                     <Img
//                       key={i}
//                       w="70px"
//                       h="70px"
//                       m="2"
//                       borderRadius={"5px"}
//                       src={medium.imgUrl}
//                       alt={medium.file.name}
//                     />
//                   );
//                 })}
//               </Flex>
//             </Flex>
//             <Box pt="8vh">
//               <PrimaryButton
//                 btnText="Send Report"
//                 isLoading={loading}
//                 onClick={handleOneIssueDisplay}
//               />
//             </Box>
//           </FormControl>
//          <Text textAlign="center" py="10" fontWeight="600">
//             <Link to={ISSUE_REPORTS_HISTORY}>Goto report history</Link>
//               </Text>
//         </Container>
//         <Footer />
//       </Box>
//     </SlideFade>
//   ) : (
//     <OneIssue
//       setDisplayOneIssue={setDisplayOneIssue}
//       media={media}
//       description={description}
//       issueType={issueType}
//       title={title}
//       previewImg={previewImg}
//       setLoading={setLoading}
//     />
//   );
// };

// export default IssueReport;
