import {
  Box,
  Img,
  Stack,
  VStack,
  Text,
  Grid,
  Flex,
  useColorModeValue,
  Button,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { v4 } from "uuid";
import { PROTECTED_PATHS } from "../../../app/constants";
import Container from "../../../components/Container";
import FullPageLoader from "../../../components/FullPageLoader";
import Header from "../../../components/Header";
import { AuthContext } from "../../../context/authContext";
import SearchBar from "./SearchBar";
import { Link, useNavigate } from "react-router-dom";
import { getCategories } from "../service";
import { COLORS } from "../../../constants/colors";

const ItemDescription = () => {
  const cardBg = useColorModeValue(COLORS.primary, COLORS.white);

  const { userInfo } = useContext(AuthContext);
  const { userId } = userInfo;
  const [loading, setLoading] = useState(false);
  const { MARKET_PLACE, SAME_CATEGORY, SPECIFIC_PRODUCT } = PROTECTED_PATHS;
  const [input, setInput] = useState("");
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();
  // const parentPath = dynamicPathhandler(CATEGORY);

  useEffect(() => {
    getCategories(userId, setLoading, setCategories);
  }, [userId]);

  return loading ? (
    <FullPageLoader />
  ) : (
    <Stack>
      <Box shadow="sm">
        <Container>
          <Header goto={SPECIFIC_PRODUCT} />
        </Container>
      </Box>

      <Container>
        <Box>
          <Text color={cardBg} fontSize="20px">
            Macbook Pro 2016
          </Text>
          {categories?.slice(0, 1).map((category, index) => (
            <>
              <Img
                w={"100%"}
                h="230px"
                // objectFit={"cover"}
                rounded="2xl"
                pt="10px"
                src={category?.image}
                alt={category?.categoryName}
              />
              <Box bg="#D9D9D9" rounded="2xl" p="15px" mt="20px">
                <Img
                  w={"110px"}
                  h={"110px"}
                  rounded="2xl"
                  pt="10px"
                  src={category?.image}
                  alt={category?.categoryName}
                />
              </Box>
              <Box bg="#fff" rounded="2xl" p="20px" mt="20px">
                <Flex justify="space-between">
                  <Text color="gray">Price</Text>
                  <Text color={COLORS.green} fontWeight="semibold">
                    380,000 NGN
                  </Text>
                </Flex>
                <Flex justify="space-between" py="15px">
                  <Text color="gray">Quantity</Text>
                  <Text fontWeight="semibold">SQTY</Text>
                </Flex>
                <Text color="gray">Description</Text>
                <Text pt="10px">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Unde
                  molestiae nostrum ipsa, voluptatibus possimus earum dolore
                  illo alias eaque culpa beatae non quibusdam quo
                </Text>
              </Box>
              <Flex justify="space-between" mt="30px">
                <Button
                  w="170px"
                  color={cardBg}
                  size="lg"
                  bg="transparent"
                  rounded="lg"
                  border={`1px solid ${cardBg}`}
                >
                  Send Message
                </Button>
                <Button
                  w="170px"
                  size="lg"
                  color={cardBg}
                  bg={COLORS.green}
                  _hover={{ backgroundColor: `${COLORS.green}` }}
                  rounded="lg"
                >
                  Call Now
                </Button>
              </Flex>
            </>
          ))}
        </Box>
      </Container>
    </Stack>
  );
};

export default ItemDescription;
