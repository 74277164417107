import React, { useEffect, useState } from "react";
import Container from "../../components/Container";
import { BsArrowLeft } from "react-icons/bs";
import { PrimaryButton } from "../../components/CustomButton";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { inviteGuest } from "./service/inviteGuest";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import ShareInviteLink from "./components/ShareInviteLink";
import { PROTECTED_PATHS } from "../../app/constants";
import ShareModal from "./components/ShareModal";
import CustomModal from "../../components/CustomModal";
import { infoNotifier } from "../../components/NotificationHandler";
import { pickContactFromPhone } from "./service/addFromContact";
import { timeout } from "../../components/utils/timeout";
import PickContactFromPhoneWarning from "./components/PickContactFromPhoneWarning";
import { UserProfile } from "../../context/userProfileContext";
import { COLORS } from "../../constants/colors";
import CustomizeVisitModal from "./components/CustomizeVisitModal";
import "./InviteGuest.css";

import {
  Box,
  Center,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  SlideFade,
  Stack,
  Switch,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";

const InviteGuest = () => {
  const today = new Date();
  const currentTime = dayjs(today).format("HH:mm");
  const appTextColor = useColorModeValue(COLORS.primary, COLORS.white);
  const inputColor = useColorModeValue(COLORS.lightGrey, COLORS.white);
  const btnBg = useColorModeValue(COLORS.primaryDark, COLORS.green);
  const iconColor = useColorModeValue(COLORS.white, COLORS.primary);
  const nameColor = useColorModeValue(COLORS.black, COLORS.primary);
  const { MY_ESTATE } = PROTECTED_PATHS;
  const navigate = useNavigate();
  const url = window?.location.href;
  const urlString = new URL(url);
  const guestName = urlString?.searchParams?.get("name") || "";
  const guestPhone = urlString?.searchParams?.get("phone")?.trim() || "";
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [startTime, setStartTime] = useState("00:00");
  const [endTime, setEndTime] = useState("00:00");
  const [allowCompany, setAllowCompany] = useState(false);
  const [loading, setLoading] = useState(false);
  const [guestCode, setGuestCode] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [disable, setDisable] = useState(true);
  const [name, setName] = useState(guestName);
  const [phone, setPhone] = useState(guestPhone);
  const { userProfile } = UserProfile();
  const { _id, tenantCode } = userProfile;

  const initialValues = {
    tenantCode,
    userId: _id,
    oneTimeVisit: true,
    allowCompany,
    startDate: dayjs(today).format("YYYY-MM-DD"),
    endDate: dayjs(today).format("YYYY-MM-DD"),
    startTime: currentTime,
    endTime,
    // days: [],
  };

  const [values, setValues] = useState(initialValues);

  const handleStartDate = (date) => {
    setStartDate(date);
    const formated = dayjs(date).format("YYYY-MM-DD");
    const formatedDate = formated;

    setValues((values) => ({
      ...values,
      startDate: formatedDate,
    }));
  };
  const handleEndDate = (date) => {
    setEndDate(date);
    const formated = dayjs(date).format("YYYY-MM-DD");
    const formatedDate = formated;

    setValues((values) => ({
      ...values,
      endDate: formatedDate,
    }));
  };

  const handleStartTime = (event) => {
    const time = event.target.value;
    setStartTime(time);

    // Calculate endTime as 3 hours from startTime
    const start = dayjs(`${dayjs(today).format("YYYY-MM-DD")} ${time}`);
    const end = start.add(3, "hours");
    const endTimeFormatted = end.format("HH:mm");

    setEndTime(endTimeFormatted);

    setValues((values) => ({
      ...values,
      startTime: time,
      endTime: endTimeFormatted,
    }));
  };

  const handleEndTime = (event) => {
    const time = event.target.value;
    setEndTime(time);

    setValues((values) => ({
      ...values,
      endTime: time,
    }));
  };

  const handleAllowCompany = () => {
    setAllowCompany(!allowCompany);
    setValues((values) => ({ ...values, allowCompany: !allowCompany }));
  };

  const handleInvite = () => {
    if (disable) {
      infoNotifier("Enter Guest name and Phone number first");
      return;
    }
    const payload = { ...values, name, phone };
    inviteGuest(payload, setLoading, setGuestCode, onOpen, onClose);
  };

  const handlepickContactFromPhone = () => {
    pickContactFromPhone(setName, setPhone);
  };

  useEffect(() => {
    name && phone ? setDisable(false) : setDisable(true);
  }, [name, phone]);

  const handleShareClose = () => {
    onClose();
    timeout(() => navigate(MY_ESTATE), 1000);
  };

  return (
    <SlideFade in={true} offsetY="500px">
      <Stack pt="3vh" width="100%" color={appTextColor}>
        <Container>
          <Flex
            py="4"
            mb="5"
            shadow="sm"
            align="center"
            justify="space-between"
          >
            <Box>
              <Link to={PROTECTED_PATHS.GUEST_LIST}>
                <BsArrowLeft size={25} />
              </Link>
            </Box>
            <Text
              flex={0.65}
              fontSize="1.2rem"
              fontWeight="600"
              color={appTextColor}
            >
              Invite Guest
            </Text>
          </Flex>
        </Container>

        <Container>
          <Center py="3">
            <PrimaryButton
              btnText="Add from contact"
              onClick={handlepickContactFromPhone}
              w="100%"
              // maxW="300px"
            />
          </Center>
          <HStack>
            <Divider />
            <Text>Or</Text>
            <Divider />
          </HStack>
          <FormControl>
            <Stack spacing="5" pb="5">
              <Box>
                <FormLabel htmlFor="name" mb="0">
                  Guest Name
                </FormLabel>
                <InputGroup
                  bg={inputColor}
                  color={iconColor}
                  borderRadius=".2rem"
                  mt="5px"
                >
                  <Input
                    id="name"
                    color={nameColor}
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Name"
                  />
                </InputGroup>
              </Box>

              <Box>
                <FormLabel htmlFor="phone" m="0">
                  Guest Phone Number
                </FormLabel>
                <InputGroup
                  bg={inputColor}
                  color={iconColor}
                  borderRadius=".2rem"
                  mt="5px"
                >
                  <Input
                    name="phone"
                    id="phone"
                    color={nameColor}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder="08055..."
                    // type='number'
                  />
                </InputGroup>
              </Box>
              <Flex
                justify="space-between"
                // wrap="wrap"
                gap="1rem"
                width="100%"
                direction="row"
              >
                <Stack spacing="0" w={"50%"}>
                  <Text>Start Date</Text>
                  <Box bg={appTextColor} borderRadius=".5rem" color={iconColor}>
                    <DatePicker
                      minDate={new Date()}
                      dateFormat={"dd/MM/yyyy"}
                      selected={startDate}
                      onChange={(date) => handleStartDate(date)}
                    />
                  </Box>
                </Stack>
                <Stack spacing="0" w={"50%"}>
                  <Text>End Date</Text>
                  <Box bg={appTextColor} borderRadius=".5rem" color={iconColor}>
                    <DatePicker
                      minDate={new Date()}
                      dateFormat={"dd/MM/yyyy"}
                      selected={endDate}
                      onChange={(date) => handleEndDate(date)}
                    />
                  </Box>
                </Stack>
              </Flex>
              <Flex
                justify="space-between"
                gap="1rem"
                width="100%"
                direction="row"
              >
                <Stack spacing="0" w={"50%"}>
                  <Text>Start Time</Text>
                  <Box bg={appTextColor} borderRadius=".5rem" color={iconColor}>
                    <Input
                      type="time"
                      value={startTime}
                      onChange={handleStartTime}
                    />
                  </Box>
                </Stack>
                <Stack spacing="0" w={"50%"}>
                  <Text>End Time</Text>
                  <Box bg={appTextColor} borderRadius=".5rem" color={iconColor}>
                    <Input
                      type="time"
                      value={endTime}
                      onChange={handleEndTime}
                    />
                  </Box>
                </Stack>
              </Flex>
              <Stack spacing="0">
                <Text>Visit Type</Text>
                <HStack
                  justify="space-between"
                  gap={["0.5rem", "1rem"]}
                  // py="2"
                  wrap="wrap"
                >
                  <Flex justify="space-between" w="100%">
                    <PrimaryButton
                      size="sm"
                      w={["140px", "150px"]}
                      btnText="One Time Visit"
                      rightIcon={<Checkbox isChecked={true} />}
                    />
                    <CustomModal
                      size="xs"
                      customVisit
                      name={name}
                      phone={phone}
                      startTime={startTime}
                      endTime={endTime}
                      buttonProps={{
                        size: "sm",
                        bg: btnBg,
                        w: ["140px", "150px"],
                        // isDisabled: disable,
                      }}
                      btnTitle="Custom Visit"
                    >
                      <CustomizeVisitModal name={name} phone={phone} />
                    </CustomModal>
                  </Flex>
                </HStack>
              </Stack>

              <ShareModal
                size="xs"
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={handleShareClose}
              >
                <ShareInviteLink onClose={onClose} guestCode={guestCode} />
              </ShareModal>
              <PickContactFromPhoneWarning />
            </Stack>
          </FormControl>
          <Flex justify={"space-between"}>
            <Text pr="3">Allow Company</Text>
            <Switch
              colorScheme="green"
              isChecked={allowCompany}
              onChange={handleAllowCompany}
            />
          </Flex>
          <Box textAlign="center" bottom="20">
            <PrimaryButton
              w="100%"
              my="40px"
              onClick={handleInvite}
              isLoading={loading}
              btnText="Send Invite"
            />
          </Box>
        </Container>
      </Stack>
    </SlideFade>
  );
};

export default InviteGuest;

// import {
//   Box,
//   Center,
//   Checkbox,
//   Divider,
//   Flex,
//   FormControl,
//   FormLabel,
//   HStack,
//   Input,
//   InputGroup,
//   InputLeftElement,
//   SlideFade,
//   Stack,
//   Switch,
//   Text,
//   useDisclosure,
//   useColorModeValue,
//   Select,
// } from "@chakra-ui/react";
// import React, { useEffect, useState } from "react";
// import Container from "../../components/Container";
// import { BsArrowLeft } from "react-icons/bs";
// import { PrimaryButton } from "../../components/CustomButton";
// import { RiUser3Fill } from "react-icons/ri";
// import { BsPhone } from "react-icons/bs";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import { inviteGuest } from "./service/inviteGuest";
// import { Link, useNavigate } from "react-router-dom";
// import dayjs from "dayjs";
// import ShareInviteLink from "./components/ShareInviteLink";
// import { PROTECTED_PATHS } from "../../app/constants";
// import ShareModal from "./components/ShareModal";
// import CustomModal from "../../components/CustomModal";
// import CustomizeVisitModal from "./components/CustomizeVisitModal";
// import { infoNotifier } from "../../components/NotificationHandler";
// import { pickContactFromPhone } from "./service/addFromContact";
// import { timeout } from "../../components/utils/timeout";
// import PickContactFromPhoneWarning from "./components/PickContactFromPhoneWarning";
// import { UserProfile } from "../../context/userProfileContext";
// import { COLORS } from "../../constants/colors";
// // import CustomizeVisit from './components/CustomizeVisit';

// const InviteGuest = () => {
//   const today = new Date();
//   const appTextColor = useColorModeValue(COLORS.primary, COLORS.white);
//   const inputColor = useColorModeValue(COLORS.lightGrey, COLORS.white);
//   const btnBg = useColorModeValue(COLORS.primaryDark, COLORS.green);
//   const iconColor = useColorModeValue(COLORS.white, COLORS.primary);
//   const { MY_ESTATE } = PROTECTED_PATHS;
//   const navigate = useNavigate();
//   const url = window?.location.href;
//   const urlString = new URL(url);
//   const guestName = urlString?.searchParams?.get("name") || "";
//   const guestPhone = urlString?.searchParams?.get("phone")?.trim() || "";
//   const [startDate, setStartDate] = useState(today);
//   const [endDate, setEndDate] = useState(today);
//   const [allowCompany, setAllowCompany] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [guestCode, setGuestCode] = useState("");
//   const { isOpen, onOpen, onClose } = useDisclosure();
//   const [disable, setDisable] = useState(true);
//   const [name, setName] = useState(guestName);
//   const [phone, setPhone] = useState(guestPhone);
//   const { userProfile } = UserProfile();
//   const { _id, tenantCode } = userProfile;

//   const initialValues = {
//     tenantCode,
//     userId: _id,
//     oneTimeVisit: true,
//     allowCompany,
//     startDate: dayjs(today).format("YYYY-MM-DD"),
//     endDate: dayjs(today).format("YYYY-MM-DD"),
//     // days: [],
//   };

//   const [values, setValues] = useState(initialValues);

//   const handleStartDate = (date) => {
//     setStartDate(date);
//     const formated = dayjs(date).format("YYYY-MM-DD");
//     const formatedDate = formated;

//     setValues((values) => ({
//       ...values,
//       startDate: formatedDate,
//     }));
//   };
//   const handleEndDate = (date) => {
//     setEndDate(date);
//     const formated = dayjs(date).format("YYYY-MM-DD");
//     const formatedDate = formated;

//     setValues((values) => ({
//       ...values,
//       endDate: formatedDate,
//     }));
//   };

//   const handleAllowCompany = () => {
//     setAllowCompany(!allowCompany);
//     setValues((values) => ({ ...values, allowCompany: !allowCompany }));
//   };

//   const handleInvite = () => {
//     if (disable) {
//       infoNotifier("Enter Guest name and Phone number first");
//       return;
//     }
//     const payload = { ...values, name, phone };
//     inviteGuest(payload, setLoading, setGuestCode, onOpen, onClose);
//   };

//   const handlepickContactFromPhone = () => {
//     pickContactFromPhone(setName, setPhone);
//   };

//   useEffect(() => {
//     name && phone ? setDisable(false) : setDisable(true);
//   }, [name, phone]);

//   const handleShareClose = () => {
//     onClose();
//     timeout(() => navigate(MY_ESTATE), 1000);
//   };

//   return (
//     <SlideFade in={true} offsetY="500px">
//       <Container px="10%" color={appTextColor} width="100%" minW="400px">
//         <Stack pt="3vh" width="100%">
//           <Flex
//             py="4"
//             mb="5"
//             shadow="sm"
//             align="center"
//             justify="space-between"
//           >
//             <Box>
//               <Link to={PROTECTED_PATHS.GUEST_LIST}>
//                 <BsArrowLeft size={25} />
//               </Link>
//             </Box>
//             <Text
//               flex={0.65}
//               fontSize="1.2rem"
//               fontWeight="600"
//               color={appTextColor}
//             >
//               Invite Guest
//             </Text>
//           </Flex>

//           <Center py="3">
//             <PrimaryButton
//               btnText="Add from contact"
//               onClick={handlepickContactFromPhone}
//               w="100%"
//               // maxW="300px"
//             />
//           </Center>
//           <HStack>
//             <Divider />
//             <Text>Or</Text>
//             <Divider />
//           </HStack>
//           <FormControl>
//             <Stack spacing="5" pb="5">
//               <Box>
//                 <FormLabel htmlFor="name" mb="0">
//                   Guest Name
//                 </FormLabel>
//                 <InputGroup
//                   bg={inputColor}
//                   color={iconColor}
//                   borderRadius=".2rem"
//                   mt="5px"
//                 >
//                   <Input
//                     id="name"
//                     color={iconColor}
//                     name="name"
//                     value={name}
//                     onChange={(e) => setName(e.target.value)}
//                     placeholder="Ciroma"
//                   />
//                 </InputGroup>
//               </Box>

//               <Box>
//                 <FormLabel htmlFor="phone" m="0">
//                   Guest Phone Number
//                 </FormLabel>
//                 <InputGroup
//                   bg={inputColor}
//                   color={iconColor}
//                   borderRadius=".2rem"
//                   mt="5px"
//                 >
//                   <Input
//                     name="phone"
//                     id="phone"
//                     value={phone}
//                     onChange={(e) => setPhone(e.target.value)}
//                     placeholder="08055..."
//                     // type='number'
//                   />
//                 </InputGroup>
//               </Box>
//               <Flex
//                 justify="space-between"
//                 // wrap="wrap"
//                 gap="1rem"
//                 width="100%"
//                 direction="row"
//               >
//                 <Stack spacing="0">
//                   <Text>Start date</Text>
//                   <Box bg={appTextColor} borderRadius=".5rem" color={iconColor}>
//                     <DatePicker
//                       minDate={new Date()}
//                       dateFormat={"dd/MM/yyyy"}
//                       selected={startDate}
//                       onChange={(date) => handleStartDate(date)}
//                     />
//                   </Box>
//                 </Stack>
//                 <Stack spacing="0">
//                   <Text>End date</Text>
//                   <Box bg={appTextColor} borderRadius=".5rem" color={iconColor}>
//                     <DatePicker
//                       minDate={new Date()}
//                       selected={endDate}
//                       dateFormat="dd/MM/yyyy"
//                       onChange={(date) => handleEndDate(date)}
//                     />
//                   </Box>
//                 </Stack>
//               </Flex>
//               {/*<Select
//                 bg={inputColor}
//                 color={"grey"}
//                 borderRadius=".2rem"
//                 placeholder="Select option"
//               >
//                 <option value="option1">One time visit</option>
//                 <option value="option2">Custom Visit</option>
//               </Select>
//               <Box>
//                 <FormLabel htmlFor="name" mb="0">
//                   Visit Type
//                 </FormLabel>
//                 <InputGroup
//                   bg={inputColor}
//                   color={iconColor}
//                   borderRadius=".2rem"
//                   mt="5px"
//                 >
//                   <Input
//                     id="visit"
//                     color={iconColor}
//                     name="visit"
//                     value={name}
//                     placeholder="custom visit"
//                   />
//                 </InputGroup>
//   </Box>*/}
//               <Stack spacing="0">
//                 <Text>Visit Type</Text>
//                 <HStack
//                   justify="space-between"
//                   gap={["0.5rem", "1rem"]}
//                   // py="2"
//                   wrap="wrap"
//                 >
//                   <Flex justify="space-between" w="100%">
//                     <PrimaryButton
//                       size="sm"
//                       w={["140px", "150px"]}
//                       btnText="One Time Visit"
//                       rightIcon={<Checkbox isChecked={true} />}
//                     />
//                     <CustomModal
//                       size="sm"
//                       customVisit
//                       name={name}
//                       phone={phone}
//                       buttonProps={{
//                         size: "sm",
//                         bg: btnBg,
//                         w: ["140px", "150px"],
//                         // isDisabled: disable,
//                       }}
//                       btnTitle="Custom Visit"
//                     >
//                       <CustomizeVisitModal name={name} phone={phone} />
//                     </CustomModal>
//                   </Flex>
//                 </HStack>
//               </Stack>

//               {/*<Flex
//                 justify="space-between"
//                 // wrap="wrap"
//                 gap="1rem"
//                 py="5"
//                 width="100%"
//                 direction="row"
//               >
//                 <Stack spacing="0">
//                   <Text>Start date</Text>
//                   <Box bg={appTextColor} borderRadius=".5rem" color={iconColor}>
//                     <DatePicker
//                       minDate={new Date()}
//                       dateFormat={"dd/MM/yyyy"}
//                       selected={startDate}
//                       onChange={(date) => handleStartDate(date)}
//                     />
//                   </Box>
//                 </Stack>
//                 <Stack spacing="0">
//                   <Text>End date</Text>
//                   <Box bg={appTextColor} borderRadius=".5rem" color={iconColor}>
//                     <DatePicker
//                       minDate={new Date()}
//                       selected={endDate}
//                       dateFormat="dd/MM/yyyy"
//                       onChange={(date) => handleEndDate(date)}
//                     />
//                   </Box>
//                 </Stack>
//                     </Flex>*/}

//               <ShareModal
//                 size="xs"
//                 isOpen={isOpen}
//                 onOpen={onOpen}
//                 onClose={handleShareClose}
//               >
//                 <ShareInviteLink onClose={onClose} guestCode={guestCode} />
//               </ShareModal>
//               <PickContactFromPhoneWarning />
//             </Stack>
//           </FormControl>
//           <Flex justify={"space-between"}>
//             <Text pr="3">Allow Company</Text>
//             <Switch isChecked={allowCompany} onChange={handleAllowCompany} />
//           </Flex>
//           <Box textAlign="center" bottom="20">
//             <PrimaryButton
//               w="100%"
//               my="40px"
//               onClick={handleInvite}
//               isLoading={loading}
//               btnText="Send Invite"
//             />
//           </Box>
//         </Stack>
//       </Container>
//     </SlideFade>
//   );
// };

// export default InviteGuest;
