import {
  Box,
  Button,
  Flex,
  Img,
  Square,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { PROTECTED_PATHS } from "../../../app/constants";
import { FiCopy } from "react-icons/fi";
import { COLORS } from "../../../constants/colors";
import { BsFillCreditCardFill } from "react-icons/bs";
import { AccountStatementModal } from "./AccountStatementModal";
import { BuyUtilityModal } from "./BuyUtilityModal";
import bill from "../Bill.png";
import CustomModal from "../../../components/CustomModal";
import RedirectToPaymentPageModal from "./RedirectToPaymentPageModal";
import { FaWallet } from "react-icons/fa";
import { PrimaryButton } from "../../../components/CustomButton";
import { RiLightbulbFlashFill } from "react-icons/ri";
import { useEffect, useState } from "react";
import { getAccount } from "../service";
import { successNotifier } from "../../../components/NotificationHandler";

const DebitCard = ({ setReload, setLoading, wallet, data, onClose, userId }) => {
  const navigate = useNavigate();
  const bgColor = useColorModeValue(COLORS.primary, COLORS.green);
  const pageBg = useColorModeValue(COLORS.bgGrey, COLORS.darkModeBg);
  const { ESTATE_DUE, SERVICES } = PROTECTED_PATHS;

  // const cardBg = useColorModeValue(COLORS.primary, COLORS.white);

  const copyToClippBoard = () => {
    navigator.clipboard
      .writeText(`${wallet?.accountNo} ${wallet?.bank}`)
      .then(() => {
        successNotifier("Copied successfully");
        onClose();
      });
  };
  return (
    <>
      <Square
        className="wallet-debit-card"
        rounded="lg"
        h="180px"
        w="100%"
        bg="#E4EDE6"
      >
        <Box w="100%" p="20px">
          <Flex justify="space-between" pb="50px">
            <Text>Virtual Account</Text>
            <FiCopy
              size="1.5rem"
              color={bgColor}
              cursor="pointer"
              onClick={copyToClippBoard}
            />
          </Flex>
          <Text fontSize="18px">{wallet?.bank || "--"}</Text>
          <Flex justify="space-between">
            <Text fontSize="20px" fontWeight="semibold">
              {wallet?.accountNo || "--"}
            </Text>
            <Text zIndex={1}>{wallet?.bank || "--"}</Text>
          </Flex>
          <Box position="absolute" right={["20px", "46px"]} top="219px">
            <Img src={bill} alt="background" />
          </Box>
        </Box>
      </Square>
      <AccountStatementModal data={data} userId={userId} wallet={wallet} setLoading={setLoading} />
      <Flex justify="space-between" color={pageBg}>
        <Button
          leftIcon={<BsFillCreditCardFill color={COLORS.lightPink} />}
          w="46%"
          onClick={() => navigate(ESTATE_DUE)}
          // onClick={() => navigate(PENDING_BILLS)}
          bg={COLORS.green}
          color={COLORS.white}
          _hover={{ backgroundColor: `${COLORS.green}` }}
        >
          Estate Bills
        </Button>
        <Button
          leftIcon={<RiLightbulbFlashFill color="navyBlue" />}
          w="46%"
          bg={COLORS.green}
          color={COLORS.white}
          _hover={{ backgroundColor: `${COLORS.green}` }}
          onClick={() => navigate(SERVICES)}
        >
          Buy Utility
        </Button>
        {/*<BuyUtilityModal />*/}
      </Flex>
    </>
  );
};

export default DebitCard;

// import { Box, Circle, HStack, Square, Text, VStack } from '@chakra-ui/react';
// import { useNavigate } from 'react-router-dom';
// import { PROTECTED_PATHS } from '../../../app/constants';
// import { PrimaryButton } from '../../../components/CustomButton';
// import CustomModal from '../../../components/CustomModal';
// import { FaWallet } from 'react-icons/fa';
// import RedirectToPaymentPageModal from './RedirectToPaymentPageModal';

// const DebitCard = ({ setReload, wallet }) => {
//   const navigate = useNavigate();
//   const { ESTATE_DUE } = PROTECTED_PATHS;

//   return (
//     <>
//       <Square className='wallet-debit-card' borderRadius='8px' h='200px' w='100%' color='#eee'>
//         <HStack justify='space-between' px='5' pt='24%' w='100%'>
//           <Box>
//             <Text as='small' m='0'>
//               CURRENT BALANCE
//             </Text>
//             <Text fontSize='1.5rem' lineHeight={1}>
//               {Number(wallet?.balance || 0)?.toFixed(2)}
//               <Text as='span' fontSize='12px'>
//                 points
//               </Text>
//             </Text>
//           </Box>

//           <CustomModal div btnTitle={<BuyPoints />}>
//             <RedirectToPaymentPageModal setReload={setReload} />
//           </CustomModal>
//         </HStack>
//       </Square>{' '}
//       <Box py='5'>
//         <PrimaryButton shadow='sm' btnText='Pay Estate Due' onClick={() => navigate(ESTATE_DUE)} />
//       </Box>
//       {/* <HStack gap='2rem' py='3' justify='space-between'>
//         <CustomModal
//           btnTitle='withdraw funds'
//           buttonProps={{ ...fundsBtnStyles }}
//         >
//           <WithdrawFunds />
//         </CustomModal>

//         <CustomModal
//           btnTitle='Transfer funds'
//           buttonProps={{ ...fundsBtnStyles }}
//         >
//           <TransaferFunds />
//         </CustomModal>
//       </HStack> */}
//     </>
//   );
// };

// export default DebitCard;

// // const fundsBtnStyles = {
// //   color: 'primary',
// //   bg: 'lightPink',
// //   border: 'solid 1px #FBD5D3',
// //   size: 'sm',
// //   px: '6',
// // };

// const BuyPoints = () => {
//   return (
//     <VStack>
//       <Circle h='30px' w='30px' border='1px solid #ddd'>
//         <FaWallet />
//       </Circle>
//       <Text as='small' m='0'>
//         Buy Points
//       </Text>
//     </VStack>
//   );
// };
