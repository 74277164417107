import {
  Box,
  Button,
  Flex,
  HStack,
  Stack,
  StackDivider,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import { FiShare2 } from "react-icons/fi";
import { BsDownload } from "react-icons/bs";
import { PROTECTED_PATHS } from "../../../app/constants";
import Container from "../../../components/Container";
import Header from "../../../components/Header";
import { useContext, useEffect, useState } from "react";
import { UserProfileContext } from "../../../context/userProfileContext";
// import QRCode from 'qrcode';
import CustomeSpecialReqestModal from "./CustomeSpecialReqestModal";
import ShareInviteLink from "../../invite-guest/components/ShareInviteLink";
import { TiPrinter } from "react-icons/ti";
import { jsPDF } from "jspdf";
// import { QRCodeSVG } from 'qrcode.react';

import QRCode from "qrcode.react";
import { successNotifier } from "../../../components/NotificationHandler";
import { COLORS } from "../../../constants/colors";
import dayjs from "dayjs";

export const EReceipt = ({ verifyResponse }) => {
  const { userProfile } = useContext(UserProfileContext);
  const { tenantName } = userProfile;
  const [showButtons, setShowButtons] = useState(true);
  const { SPECIAL_GUEST_REQUEST } = PROTECTED_PATHS;
  const bttmText = {
    fontSize: "1rem",
    fontWeight: "600",
    color: `${COLORS.green}`,
  };
  // const [qrcode, setQrcode] = useState('');
  const { onClose } = useDisclosure();
  const [qrcodeValue, setQrcodeValue] = useState();

  // useEffect(() => {
  //   QRCode.toDataURL(verifyResponse?.inviteCode).then((code) =>
  //     setQrcode(code)
  //   );
  // }, [verifyResponse?.inviteCode]);

  // const generatePDF = () => {
  //   // Defines the pdf
  //   let pdf = new jsPDF({
  //     orientation: 'landscape',
  //     unit: 'mm',
  //     format: [40, 40],
  //   });

  //   // Transforms the canvas into a base64 image
  //   let base64Image = document.getElementById('qrcode').toDataURL('Blob');
  //   console.log('base64Image', base64Image);
  //   // Adds the image to the pdf
  //   pdf.addImage(base64Image, 'png', 0, 0, 40, 40);

  //   // Downloads the pdf
  //   pdf.save('QR.pdf');
  // };
  console.log(verifyResponse, "reponsee");

  useEffect(() => {
    setQrcodeValue(verifyResponse?.inviteCode);
  }, [verifyResponse?.inviteCode]);

  const downloadQRCode = () => {
    const qrCodeURL = document
      .getElementById("qrCodeEl")
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    console.log(qrCodeURL);
    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = "QR_Code.png";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);

    successNotifier("downloded successfully");
  };

  const downloadQr = () => {
    var doc = new jsPDF();

    // Source HTMLElement or a string containing HTML.
    var elementHTML = document.querySelector("#pdf-container");

    doc.html(elementHTML, {
      callback: function (doc) {
        // Save the PDF
        doc.save("sample-document.pdf");
      },
      x: 15,
      y: 15,
      width: 170, //target width in the PDF document
      windowWidth: 650, //window width in CSS pixels
    });
  };
  const handlePrint = () => {
    setShowButtons(false);
    setTimeout(() => {
      window.print();
    }, 1000);
  };

  return (
    <Box>
      <Box shadow="sm" display={showButtons === false ? "none" : "block"}>
        <Container>
          <Header goto={SPECIAL_GUEST_REQUEST} />
        </Container>
      </Box>
      <Container p="5" bg="#fff">
        <Stack
          id="pdf-container"
          minH="80vh"
          spaicing="16"
          borderRadius="5px"
          divider={<StackDivider borderColor="gray.200" />}
        >
          <Stack py="4">
            <Text>Name of Resident </Text>
            <Text {...bttmText}>{tenantName}</Text>
          </Stack>
          <Stack py="4">
            <Text>Name of Guest </Text>
            <Text {...bttmText}>{verifyResponse?.contactPerson}</Text>
          </Stack>
          <Stack py="4">
            <Text>Phone Number of Guest </Text>
            <Text {...bttmText}>{verifyResponse?.contactPersonPhone}</Text>
          </Stack>
          <Stack py="4">
            <Text>Number of days </Text>
            <Text {...bttmText}>{verifyResponse?.dates?.length} days </Text>
          </Stack>
          <Stack py="4">
            <Text>Days of Visit </Text>
            {verifyResponse.dates.map((date, i) => (
              <Text key={i} {...bttmText}>
                {dayjs(date)?.format("DD-MMMM-YYYY")}
              </Text>
            ))}
          </Stack>
          <Stack py="4">
            <Text>Number of days </Text>
            <Text {...bttmText}>{verifyResponse?.dates?.length} days </Text>
          </Stack>
          <Stack py="4">
            <Text>Number of Guest </Text>
            <Text {...bttmText}>{verifyResponse?.numberOfGuests}</Text>
          </Stack>
          <Stack py="4">
            <Text>Guest Description </Text>
            <Text {...bttmText}>{verifyResponse?.type} </Text>
          </Stack>
          <HStack justify="space-betweens" gap="3rem">
            <Stack>
              <Text>Code</Text>
              <Text {...bttmText}>{verifyResponse?.inviteCode}</Text>
            </Stack>
            <Stack>
              <Text>Amount paid</Text>
              <Text {...bttmText}>NGN {verifyResponse?.estateFee}</Text>
            </Stack>
          </HStack>
          <Flex py="5" gap="5" align="center">
            {/* 
            <Img id='qrcode' src={qrcode} alt='invite code' />
            <input
              type='button'
              className='download-btn'
              value='Download'
              onClick={downloadQRCode}
            />
            <QRCodeSVG value={verifyResponse?.inviteCode} id='qrCodeEl' /> */}

            <QRCode id="qrCodeEl" size={140} value={qrcodeValue} />
            {showButtons && (
              <Stack spacing="6">
                <CustomeSpecialReqestModal
                  allowToOpen
                  btnTitle={
                    <HStack>
                      <FiShare2 />
                      <Button color={COLORS.green} variant="link">
                        Share E-receipt
                      </Button>
                    </HStack>
                  }
                >
                  <ShareInviteLink
                    onClose={onClose}
                    guestCode={verifyResponse?.inviteCode}
                  />
                </CustomeSpecialReqestModal>

                <HStack onClick={downloadQr}>
                  <BsDownload />
                  <Button color={COLORS.green} variant="link">
                    Download E-Receipt
                  </Button>
                </HStack>
                <HStack onClick={handlePrint}>
                  <TiPrinter />
                  <Button color={COLORS.green} variant="link">
                    Print Receipt
                  </Button>
                </HStack>
              </Stack>
            )}
          </Flex>
        </Stack>
        <Flex justify={"start"} w={"100%"}>
          <Text>Powered by MyEstate.ng</Text>
        </Flex>
      </Container>
    </Box>
  );
};

// Mr Franklyn is inviting you to Abraham Adesanya Housing estate. Please use 69873 at the access point to gain access. reach me on +2348137048054
