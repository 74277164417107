import { Button, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { COLORS } from "../constants/colors";

export const SecondaryButton = ({ btnText, ...props }) => {
  return (
    <Button
      bg="primary"
      color="#fff"
      w="100%"
      size="sm"
      _hover={{ bg: "secondary" }}
      transition="ease-in all 0.3s"
      borderRadius="7px"
      _focus={{ border: "none" }}
      {...props}
    >
      {btnText || "get started"}
    </Button>
  );
};

// export default SecondaryButton;

export const PrimaryButton = ({ btnText, isLoading, ...props }) => {
  const bgColor = useColorModeValue(COLORS.primary, COLORS.green);
  return (
    <Button
      bg={bgColor}
      color="#fff"
      w="100%"
      _hover={{ backgroundColor: `${bgColor}` }}
      transition="ease-in all 0.3s"
      borderRadius="7px"
      _focus={{ border: "none" }}
      isLoading={isLoading}
      {...props}
    >
      {btnText || "get started"}
    </Button>
  );
};
