import { Box, Grid, GridItem, HStack, Img, Square, Stack, Text } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import Container from '../../../components/Container';
import Header from '../../../components/Header';
import meat from '../../../assets/meat.png';
import { PrimaryButton } from '../../../components/CustomButton';
import Footer from '../../../components/footer';
import { Success } from '../../../components/Success';
import { AuthContext } from '../../../context/authContext';
import { useNavigate, useParams } from 'react-router-dom';
import { getSingleOfferDetails, joinOffer } from '../service/offer';
import FullPageLoader from '../../../components/FullPageLoader';
import { PROTECTED_PATHS } from '../../../app/constants';
import { v4 } from 'uuid';

const JointPurchaseInfo = () => {
  const { MARKET_PLACE } = PROTECTED_PATHS;
  const { offerId } = useParams();
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const { userInfo } = useContext(AuthContext);
  const { userId } = userInfo;
  const [singleOfferDetails, setSingleOfferDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getSingleOfferDetails(userId, offerId, setLoading, setSingleOfferDetails);
  }, [offerId, userId]);

  const handleJoinNow = () => {
    joinOffer(userId, offerId, setLoading, setSuccess, navigate);
  };

  return (
    <>
      {!success && loading && <FullPageLoader />}

      {success && !loading && <Success />}

      {!loading && !success && (
        <Box>
          <Box shadow='sm'>
            <Container>
              <Header goto={MARKET_PLACE} />
            </Container>
          </Box>
          <Container>
            <Box py='5'>
              <Img
                borderRadius='10px'
                w='100%'
                h='250px'
                shadow='lg'
                objectFit='cover'
                src={singleOfferDetails[0]?.imageUrl[0] || meat}
                alt={singleOfferDetails[0]?.itemName || 'meat'}
              />
            </Box>
            <Grid gap='1rem' templateColumns='repeat(3, 1fr)'>
              {singleOfferDetails[0]?.imageUrl?.map((image) => (
                <GridItem key={v4()} shadow='md' justifySelf={'center'}>
                  <Img
                    w='100px'
                    h='100px'
                    objectFit='cover'
                    borderRadius={'5px'}
                    src={image || meat}
                    alt={singleOfferDetails[0]?.itemName || 'meat'}
                  />
                </GridItem>
              ))}
            </Grid>
            <Stack color='#7A7A7A' my='5'>
              <Text fontWeight='900' fontSize='1.5rem'>
                {singleOfferDetails[0]?.itemName}
              </Text>
              <Text>{singleOfferDetails[0]?.itemDescription}</Text>
              <HStack justify='space-between'>
                <Text fontSize='1.2rem' fontWeight='900'>
                  {' '}
                  {singleOfferDetails[0]?.price}
                </Text>
                <Square py='1' px='2' borderRadius='5px' border='1px solid #FBD5D3' fontWeight={700}>
                  <Text pr='2' borderRight='1px solid #FBD5D3' fontSize='12px'>
                    Limited Slots Available
                  </Text>
                  <Text pl='2'>
                    {singleOfferDetails[0]?.usedSlots} 0f {singleOfferDetails[0]?.initialSlots}
                  </Text>
                </Square>
              </HStack>
            </Stack>
            <Box pb='20'>
              {singleOfferDetails[0]?.usedSlots >= singleOfferDetails[0]?.initialSlots ? (
                <SlotsFilled />
              ) : (
                <PrimaryButton onClick={handleJoinNow} btnText='Join Now' />
              )}
            </Box>
          </Container>
          <Footer />
        </Box>
      )}
    </>
  );
};

export default JointPurchaseInfo;

function SlotsFilled() {
  return (
    <Text textAlign='center' pt='5' fontSize='1rem' fontWeight={600}>
      Offer limit Filled Select another Offer to Join
    </Text>
  );
}
