import { Box, FormControl, FormLabel, Img, Input, Select, Stack, SlideFade } from '@chakra-ui/react';
import React, { useContext, useState } from 'react';
import Container from '../../../../../components/Container';
import Header from '../../../../../components/Header';
import { Success } from '../../../../../components/Success';
import { PrimaryButton } from '../../../../../components/CustomButton';
import { PROTECTED_PATHS } from '../../../../../app/constants';
import { inputBorder } from '../../../../post advert/components/data';
import { v4 } from 'uuid';
import { AuthContext } from '../../../../../context/authContext';
import { payElectricityBill } from '../../../service/electicity';
import { useNavigate, useParams } from 'react-router-dom';
import { UserProfileContext } from '../../../../../context/userProfileContext';
import { infoNotifier } from '../../../../../components/NotificationHandler';
import { chooseImageToDisplay } from '../../data';

const EKEDCPayment = () => {
  const [success, setSuccess] = useState(false);
  const { POWER_UTILITY } = PROTECTED_PATHS;
  const [plan, setPlan] = useState('');
  const [meterNumber, setMeterNumber] = useState('');
  const [amount, setAmount] = useState('');
  const { userProfile } = useContext(UserProfileContext);
  const { tenantName } = userProfile;
  const { userInfo } = useContext(AuthContext);
  const { userId } = userInfo;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { provider, discoName } = useParams();

  const handleEKEDCPayment = (discoName) => {
    const payload = {
      userId,
      amount: Number(amount) + 0.05 * Number(amount),
      meter_number: meterNumber,
      MeterType: plan,
      disco_name: discoName,
      tenantName,
    };

    if (!payload.MeterType) {
      infoNotifier('please Select a Metter type');
      return;
    }

    if (!payload.meter_number) {
      infoNotifier('Input a valid meter number');
      return;
    }

    if (!payload.amount) {
      infoNotifier('Enter the Amount you want to purchase');
      return;
    }

    payElectricityBill(setLoading, payload, setSuccess, navigate);
  };

  const planArr = ['Prepaid', 'Postpaid'];

  return success ? (
    <Success />
  ) : (
    <SlideFade in={true} offsetX='-2000px'>
      <Stack>
        <Box shadow='sm'>
          <Container>
            <Header goto={POWER_UTILITY} />
          </Container>
        </Box>
        <Container>
          <Stack py='5'>
            <Img w='80px' src={chooseImageToDisplay(provider)} alt='ekedc' />
            <FormControl>
              <Stack spacing='0'>
                <FormLabel htmlFor='plan' m='0 !important' fontSize='14px'>
                  Select Plan
                </FormLabel>
                <Select {...inputBorder} value={plan} onChange={(e) => setPlan(e.target.value)} placeholder='select your plan'>
                  {planArr.map((item) => (
                    <option key={v4()}>{item}</option>
                  ))}
                </Select>
                <FormLabel htmlFor='decoder' mb='0 !important' fontSize='14px' pt='5'>
                  Meter Number
                </FormLabel>
                <Input {...inputBorder} value={meterNumber} onChange={(e) => setMeterNumber(e.target.value)} />
                <FormLabel htmlFor='amount' mb='0' fontSize='14px' pt='5'>
                  Amount
                </FormLabel>

                <Input {...inputBorder} value={amount} onChange={(e) => setAmount(e.target.value)} placeholder='Amount' />
              </Stack>
            </FormControl>
          </Stack>
          <PrimaryButton my='6' btnText='Make Payment' isLoading={loading} onClick={() => handleEKEDCPayment(discoName)} />
        </Container>
      </Stack>
    </SlideFade>
  );
};

export default EKEDCPayment;
