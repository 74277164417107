import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Circle,
  FormControl,
  HStack,
  Img,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Stack,
  Text,
  Textarea,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import Container from "../../components/Container";
import Header from "../../components/Header";
import { inputBorder } from "./components/data";
import naira from "../../assets/naira.jpg";
import { PrimaryButton } from "../../components/CustomButton";
import { Success } from "../../components/Success";
import { useEffect, useState } from "react";
import { BsPlusLg } from "react-icons/bs";
// import { useContext } from 'react';
// import { AuthContext } from '../../context/authContext';
import { v4 } from "uuid";
import { getCategories, postAdvert } from "./service";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { infoNotifier } from "../../components/NotificationHandler";
import { UserProfile } from "../../context/userProfileContext";
import { COLORS } from "../../constants/colors";
import { BiCloudUpload } from "react-icons/bi";
import { PROTECTED_PATHS } from "../../app/constants";
import { AiOutlineCloudUpload } from "react-icons/ai";

const PostAdvert = () => {
  // const { userInfo } = useContext(AuthContext);
  // const { userId, tenantCode, tenantPhone } = userInfo;
  const { userProfile } = UserProfile();
  const bgColor = useColorModeValue(COLORS.primary, COLORS.green);
  const cardBg = useColorModeValue(COLORS.primary, COLORS.white);
  const { _id, tenantCode, tenantPhone, tenantName } = userProfile;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [success, setSuccess] = useState(false);
  const [formValues, setFormValues] = useState({
    name: tenantName,
  });
  const [adverts, setAdverts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const serviceCharge = 200;
  let imgUrlsFromCloudinary = [];

  const { SHOP } = PROTECTED_PATHS;
  const inputStyle = {
    bg: "white",
    rounded: "lg",
  };

  useEffect(() => {
    getCategories(_id, setLoading, setCategories);
  }, [_id]);

  const handleImageChange = (e) => {
    e.preventDefault();

    if (!e.target.files) return;

    if (Array(...e.target.files).length > 5) {
      infoNotifier("Pick a maximum of five Images");
      return;
    }

    if (e.target.files) {
      Array.from(e.target.files).forEach((file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = () => {
          setAdverts((prev) => [...prev, { imageUrl: reader.result, file }]);
        };
      });
    }
  };

  const handlePostAdvert = (evt, files) => {
    evt.preventDefault();
    setLoading(true);
    const uploaders = files.map((file) => {
      const formData = new FormData();

      formData.append("file", file.file);
      formData.append(
        "upload_preset",
        process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET
      );

      return axios
        .post(
          `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`,
          formData,
          {
            headers: { "X-Requested-With": "XMLHttpRequest" },
          }
        )
        .then((response) => {
          const data = response.data;
          const fileURL = data.secure_url;

          imgUrlsFromCloudinary.push(fileURL);
        });
    });

    // Once all the files are uploaded
    axios.all(uploaders).then(() => {
      // ... perform after upload is successful operation
      const { itemName, category, itemDescription, quantity, price } =
        formValues;
      const payload = {
        userId: _id,
        tenantCode,
        price: `${Number(price)}`,
        itemName,
        itemDescription,
        category: `${category.charAt(0).toUpperCase()}${category
          .slice(1)
          .toLowerCase()}`,
        quantity,
        imageUrl: imgUrlsFromCloudinary,
        phoneNumber: tenantPhone,
      };

      postAdvert(setLoading, payload, setSuccess, navigate);
    });
  };

  const onChange = (e) => {
    e.preventDefault();
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const handleOpen = () => {
    const { itemName, category, itemDescription, quantity, price } = formValues;
    if (!itemName || !category || !itemDescription || !quantity || !price) {
      infoNotifier("Please fill all fields");
      return;
    }
    if (adverts.length <= 0) {
      infoNotifier("Please add images for your Advert");
      return;
    }

    onOpen();
  };

  console.log("object", userProfile);

  return success ? (
    <Success />
  ) : (
    <Stack>
      <Box shadow="sm">
        <Container>
          <Header goto={SHOP} />
        </Container>
      </Box>
      <Container>
        <FormControl py="5">
          <Stack spacing="4">
            <Input
              {...inputStyle}
              placeholder="Name of Item"
              _placeholder={{ opacity: 1, color: "black" }}
              name="itemName"
              value={formValues?.itemName}
              onChange={onChange}
            />
            <Select
              {...inputStyle}
              placeholder="Category"
              name="category"
              color={COLORS.green}
              value={formValues?.category}
              onChange={onChange}
            >
              {categories.map((categorie) => (
                <option key={v4()}>{categorie.categoryName}</option>
              ))}
            </Select>
            <Select
              {...inputStyle}
              placeholder="Sub Category"
              color={COLORS.green}
              name="subCategory"
              value={formValues?.category}
              onChange={onChange}
            >
              {categories.map((categorie) => (
                <option key={v4()}>{categorie.categoryName}</option>
              ))}
            </Select>
            <Text fontWeight="600" color={cardBg}>
              Add Photo
            </Text>
            <Text color={cardBg}>
              First photo is the title picture. You are to add at least two and
              maximum of 5 pictures
            </Text>
            <HStack>
              <label htmlFor="advert-picture">
                <HStack
                  cursor="pointer"
                  bg={COLORS.green}
                  p={"5px 10px"}
                  rounded={"lg"}
                >
                  <Text color={cardBg} fontWeight="500">
                    Upload media
                  </Text>
                  <AiOutlineCloudUpload size={"1.5rem"} color={cardBg} />
                </HStack>
                <Input
                  type="file"
                  id="advert-picture"
                  display="none"
                  accept="image/png, image/jpeg, image/jpg"
                  multiple
                  onChange={handleImageChange}
                />
              </label>
              {adverts.map((ad) => (
                <Img
                  mx="5px"
                  w="80px"
                  h="80px"
                  objectFit="cover"
                  key={v4()}
                  src={ad.imageUrl}
                  alt="image"
                />
              ))}
            </HStack>
            <Textarea
              {...inputStyle}
              resize="none"
              rows="5"
              placeholder="Description"
              _placeholder={{ opacity: 1, color: "black" }}
              name="itemDescription"
              value={formValues?.itemDescription}
              onChange={onChange}
            />
          </Stack>
          <HStack spacing="2rem" py="15px">
            <Input
              {...inputStyle}
              flex={1}
              onChange={onChange}
              placeholder="Quantity"
              _placeholder={{ opacity: 1, color: "black" }}
              name="quantity"
              type="number"
            />
            <InputGroup flex={2}>
              <InputLeftAddon
                children={<Img w="30px" src={naira} alt="naira" />}
              />
              <Input
                {...inputStyle}
                type="number"
                placholder="price"
                name="price"
                onChange={onChange}
              />
            </InputGroup>
          </HStack>
          <Input
            {...inputStyle}
            placeholder="Name of seller"
            name="name"
            value={formValues.name}
            onChange={onChange}
          />
          <HStack py="15px" justify="space-between" gap="1.5rem">
            <Stack
              {...inputStyle}
              textAlign="center"
              bg="#c7ebe2"
              px="15px"
              w="150px"
            >
              <Text as="small">Service Charge</Text>
              <Text fontSize="0.9rem" fontWeight="700" m="0 !important">
                &#8358;{serviceCharge}
              </Text>
            </Stack>
            <Box w={["180px", "200px"]} py="15px">
              <PrimaryButton
                size="md"
                isLoading={loading}
                btnText="Post Advert"
                onClick={handleOpen}
              />
            </Box>
          </HStack>
        </FormControl>
      </Container>

      <AlertDialog
        size="xs"
        motionPreset="slideInRight"
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent borderRadius="2px">
          <AlertDialogHeader color={COLORS.green}>
            Service Charge
          </AlertDialogHeader>
          <AlertDialogCloseButton color={COLORS.green} />
          <AlertDialogBody color={COLORS.green}>
            Please note there will be a &#8358;
            <Text fontWeight="600" fontSize="1.2rem " as="span">
              {serviceCharge}
            </Text>{" "}
            service charge to post this product in the market place
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button onClick={onClose} color={COLORS.green}>
              No
            </Button>
            <Button
              bg={COLORS.green}
              color="#fff"
              w="80px"
              _hover={{ bg: `${COLORS.green}` }}
              ml={5}
              isLoading={loading}
              onClick={(e) => handlePostAdvert(e, adverts)}
            >
              Yes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Stack>
  );
};

export default PostAdvert;
