import { Box, Circle, Stack, Text } from '@chakra-ui/react';
import { RiSafe2Line } from 'react-icons/ri';
import { PrimaryButton, SecondaryButton } from '../../../components/CustomButton';
import { BiWallet } from 'react-icons/bi';
import { useContext, useState } from 'react';
import { Success } from '../../../components/Success';
import { useNavigate } from 'react-router-dom';
import { PROTECTED_PATHS } from '../../../app/constants';
import { AuthContext } from '../../../context/authContext';
import { subscribeToErrand } from '../services';

const ConfirmPaymentModal = ({ onClose, numberOfErrands, packagePrice, subscriptionId }) => {
  const { userInfo } = useContext(AuthContext);
  const { userId, tenantCode } = userInfo;
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { ERRAND_PAYMENT_GATEWAY } = PROTECTED_PATHS;

  const handleEWallet = () => {
    const payload = {
      userId,
      tenantCode,
      numberOfErrands,
      packagePrice,
      packageId: subscriptionId,
    };

    subscribeToErrand(setLoading, payload, onClose, setSuccess);
  };

  return success ? (
    <Success />
  ) : (
    <Stack pos='relative' textAlign='center'>
      <Box bg='bgColorLight'>
        <Circle className='cofirm-payment-safe'>
          <RiSafe2Line color='#FFF' size={35} />
        </Circle>
      </Box>
      <Text fontWeight='500' fontSize='1rem'>
        Confirm Payment
      </Text>
      <Text fontSize='12px'>{numberOfErrands} errands</Text>
      <Text fontSize='3rem' lineHeight='1'>
        &#8358; {packagePrice}
      </Text>
      <Stack pt='5' spacing='5' px='10%'>
        <PrimaryButton leftIcon={<BiWallet size={20} />} btnText='E wallet' isLoading={loading} onClick={handleEWallet} />
        <SecondaryButton bg='secondary' btnText='Payment gateway' onClick={() => navigate(ERRAND_PAYMENT_GATEWAY)} />
      </Stack>
    </Stack>
  );
};

export default ConfirmPaymentModal;
