import { Box, Img, Stack, SlideFade } from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { PROTECTED_PATHS } from '../../../../../app/constants';
import dstv from '../../../../../assets/dstv.png';
import Container from '../../../../../components/Container';
import { PrimaryButton } from '../../../../../components/CustomButton';
import FullPageLoader from '../../../../../components/FullPageLoader';
import Header from '../../../../../components/Header';
import { Success } from '../../../../../components/Success';
import { AuthContext } from '../../../../../context/authContext';
import { getCablePlans } from '../../../service/cable';
import PaymentForm from '../../PaymentForm';
import { reshapeWords } from './reshapeWords';
import usePayCableSubscription from './usePayCableSubscription';
import { COLORS } from '../../../../../constants/colors';

const DSTVPayment = () => {
  const {
    userInfo: { userId },
  } = useContext(AuthContext);
  const { CABLE_TV } = PROTECTED_PATHS;
  const [loading, setLoading] = useState(false);
  const [cablePlans, setCablePlans] = useState([]);

  useEffect(() => {
    getCablePlans(userId, setLoading, setCablePlans);
  }, [userId]);

  const filterDataPlansithLabel = () => {
    return cablePlans
      ?.filter((plan) => plan.provider === 'DSTV')
      ?.map((m) => ({
        ...m,
        label: `${reshapeWords(m.name)} ${m.companyAmount + m.planAmount}`,
      }));
  };

  const { isLoading, setIUCNumber, selectedOption, setSelectedOption, handlePayCableSubscription, success } = usePayCableSubscription();

  const { cableId = null, planId = null } = selectedOption;

  return (
    <>
      {!success && loading && <FullPageLoader />}

      {success && !loading && <Success />}

      {!success && !loading && (
        <SlideFade in={true} offsetX='-2000px'>
          <Stack>
            <Box shadow='sm'>
              <Container>
                <Header goto={CABLE_TV} />
              </Container>
            </Box>
            <Container>
              <Stack py='5' color={COLORS.green}>
                <Img py='5' w='130px' src={dstv} alt='dstv' />
                <PaymentForm
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                  setIUCNumber={setIUCNumber}
                  cablePlans={filterDataPlansithLabel() || []}
                />
              </Stack>
              <PrimaryButton isLoading={isLoading} my='6' btnText='Make Payment' onClick={() => handlePayCableSubscription(cableId, planId)} />
            </Container>
          </Stack>
        </SlideFade>
      )}
    </>
  );
};

export default DSTVPayment;
