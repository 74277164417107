import { HStack, Img, Stack, Text, Flex } from "@chakra-ui/react";
import meat from "../../../assets/meat.png";
import { useNavigate } from "react-router-dom";
import { PROTECTED_PATHS } from "../../../app/constants";
import { dynamicPathhandler } from "../../../components/utils/dynamicPathHandler";
import dayjs from "dayjs";

const OffersCard = ({ offers }) => {
  const navigate = useNavigate();
  const { OFFER_JOINT_ONE } = PROTECTED_PATHS;
  const parentPath = dynamicPathhandler(OFFER_JOINT_ONE);
  return (
    <Flex gap="1rem !important">
      <HStack
        my="10px"
        bg="#fff"
        shadow="md"
        rounded="2xl"
        p="15px !important"
        cursor="pointer"
        w="400px !important"
        h={"120px"}
        onClick={() => navigate(`${parentPath}/${offers._id}`)}
      >
        <Img
          w={["80px"]}
          maxW="80px"
          h={["80px"]}
          maxH={["80px"]}
          objectFit="cover"
          rounded="2xl"
          src={offers?.imageUrl[0] || meat}
          alt={offers?.itemName}
        />
        <Stack spacing={"3"} color="#7A7A7A">
          <Text fontWeight="bold">{offers?.itemName}</Text>
          <Text m="0 !important" fontSize="12px">
            45% off
          </Text>
          <Text m="0 !important" fontSize="12px">
            Offer valid till {dayjs(offers?.updateAt).format("DD/MMM/YYYY")}
          </Text>
          {/*<HStack justify="space-between">
          <Text fontWeight="bold" fontSize="14px">
            &#8358; {offers?.price}
          </Text>
  </HStack>*/}
        </Stack>
      </HStack>
    </Flex>
  );
};

export default OffersCard;
// import { Img, Stack, Text } from '@chakra-ui/react';
// import meat from '../../../assets/meat.png';
// import { useNavigate } from 'react-router-dom';
// import { PROTECTED_PATHS } from '../../../app/constants';
// import { dynamicPathhandler } from '../../../components/utils/dynamicPathHandler';

// const OffersCard = (props) => {
//   const navigate = useNavigate();
//   const { OFFER_JOINT_ONE } = PROTECTED_PATHS;
//   const parentPath = dynamicPathhandler(OFFER_JOINT_ONE);

//   return (
//     <Stack mx='2' onClick={() => navigate(`${parentPath}/${props._id}`)}>
//       <Img
//         h='100px'
//         w='120px'
//         borderRadius='5px'
//         src={props?.imageUrl[0] || meat}
//         alt={props?.itemName}
//       />
//       <Text textAlign={'center'} fontSize='12px' color='#000' fontWeight='500'>
//         {props.itemName}
//       </Text>
//       <Text
//         m='0 !important'
//         textAlign={'center'}
//         fontSize='12px'
//         color='#000'
//         fontWeight='500'
//       >
//         &#8358;{' '}
//         <Text as='span' fontSize='14px' fontWeight='600'>
//           {props.price}
//         </Text>{' '}
//         per share
//       </Text>
//     </Stack>
//   );
// };

// export default OffersCard;
