import {
  Flex,
  Text,
  useColorModeValue,
  Box,
  SlideFade,
  Container,
  Center,
  VStack,
  Image,
} from "@chakra-ui/react";
import { COLORS } from "../../../constants/colors";
import { transition } from "../../..";
import { ConfirmPaymentModal } from "./ConfirmPaymentModal";
import Header from "../../../components/Header";
import { PROTECTED_PATHS } from "../../../app/constants";
import { useLocation } from "react-router-dom";

export const PayBillsModal = () => {
  const location = useLocation();
  const PendingBills = location.state;
  const bgColor = useColorModeValue(COLORS.primary, COLORS.green);
  const TransBg = useColorModeValue(COLORS.primary, COLORS.chartbg);
  const cardBg = useColorModeValue(COLORS.lightGrey, COLORS.white);
  const { MY_WALLET } = PROTECTED_PATHS;

  console.log("PendingBills:", PendingBills);

  return (
    <SlideFade in={true} offsetY="500px">
      <Box shadow="sm" minW={["390px"]}>
        <Container>
          <Header goto={MY_WALLET} />
        </Container>
      </Box>
      <Container>
        {PendingBills.length < 1 ? (
          <Center h="calc(100vh - 150px)">
            <VStack>
              <Image src="/Empty.svg" alt="Empty" />
              <Text as="h2" py="5" fontSize="18px" color={cardBg}>
                You have no pending bills
              </Text>
            </VStack>
          </Center>
        ) : (
          <>
            {PendingBills.map((datum, index) => (
              <Box key={index} bg={TransBg} p="15px" rounded="lg" mb="20px">
                <Flex justify="space-between" mb="20px">
                  <Text color={cardBg}>{datum?.dueType || "--"}</Text>
                  <Text color={cardBg}>
                    NGN {datum?.remainingAmount || "--"}
                  </Text>
                </Flex>
                <Flex justify="space-between" align="center">
                  <Text color={COLORS.lightGrey}>{datum?.date || "--"}</Text>
                  <ConfirmPaymentModal />
                </Flex>
              </Box>
            ))}
          </>
        )}
      </Container>
    </SlideFade>
  );
};
