import { FormControl, FormLabel, Input, Stack } from '@chakra-ui/react';
import { inputBorder } from '../../../post advert/components/data';
import Select from 'react-select';

export const BuyDataForm = ({ dataPlans, setMobileNumber, mobileNumber, selectedOption, setSelectedOption }) => {
  return (
    <FormControl>
      <Stack spacing='0'>
        <FormLabel htmlFor='plan' m='0 !important' fontSize='14px'>
          Select Plan
        </FormLabel>

        <Select defaultValue={selectedOption} onChange={setSelectedOption} options={dataPlans} />
        <FormLabel htmlFor='phone-number' mb='0 !important' fontSize='14px' pt='5'>
          Phone Number
        </FormLabel>
        <Input {...inputBorder} type='number' id='phone-number' value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} />
        <FormLabel htmlFor='amount' mb='0' fontSize='14px' pt='5'>
          Amount
        </FormLabel>
        <Input
          {...inputBorder}
          readOnly
          defaultValue={selectedOption?.companyAmount + selectedOption.planAmount || ''}
          // onChange={() => null}
        />
      </Stack>
    </FormControl>
  );
};
