import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  HStack,
  Img,
  Input,
  Select,
  Stack,
  Text,
  SlideFade,
  useColorModeValue,
} from "@chakra-ui/react";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { COLORS } from "../../../constants/colors";
import { PROTECTED_PATHS } from "../../../app/constants";
import {
  errorNotifier,
  infoNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";
import Container from "../../../components/Container";
import Header from "../../../components/Header";
import { PrimaryButton } from "../../../components/CustomButton";
import { getFamily, requestCard } from "../services";
import { AuthContext } from "../../../context/authContext";
import Footer from "../../../components/footer";
import { formLabelStyles } from "../../issue report/components/issueReportData";
import ConfirmCardPayment from "./ConfirmCardPayment";
import PaystackModal from "./PaystackModal";
import axiosInstance from "../../../services/api";
import { useNavigate } from "react-router-dom";
import { getUserProfile } from "../../settings/service/getUserProfile";

const RequestId = ({ onClose }) => {
  const cardBg = useColorModeValue(COLORS.primary, COLORS.white);
  const [loading, setLoading] = useState(false);
  const [main, setMain] = useState("main");
  const { ID_CARD } = PROTECTED_PATHS;
  const serviceCharge = 3000;
  const { userInfo } = useContext(AuthContext);
  const { userId } = userInfo;
  const [family, setFamily] = useState([]);
  const [media, setMedia] = useState(null);
  const [selectedFamilyName, setSelectedFamilyName] = useState("");
  const [allowToOpen, setAllowToOpen] = useState(false);
  const [userProfile, setUserProfile] = useState(false);
  const [paymentData, setPaymentData] = useState({});
  const [paymentModal, setPaymentModal] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [payBtnDisabled, setBtnDisabled] = useState(true);
  const [formValues, setformValues] = useState({
    name: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (main === "main") {
      // Update formValues for "main"
      setformValues((prev) => ({ ...prev, [name]: value, familyName: "" }));
    } else {
      // Update formValues for "family"
      setformValues((prev) => ({ ...prev, familyName: value }));
    }
  };

  useEffect(() => {
    getUserProfile(userId, setLoading, setUserProfile);

    axiosInstance
      .get(`/card/?parent=${userId}`)
      .then((res) => {
        console.log(res?.data?.result?.data?.card, "::", userId);
        setBtnDisabled(false);
      })
      .catch(() => setBtnDisabled(true));
  }, []);

  const navigate = useNavigate();
  useEffect(() => {
    getFamily(userId, setLoading, setFamily);
  }, [userId]);

  useEffect(() => {
    // Check if all required fields are filled
    if (main === "main") {
      if (formValues.name && media) {
        setAllowToOpen(true);
      } else {
        setAllowToOpen(false);
      }
    } else {
      if (selectedFamilyName && formValues.familyName && media) {
        setAllowToOpen(true);
      } else {
        setAllowToOpen(false);
      }
    }
  }, [
    main,
    family,
    formValues.name,
    formValues.familyName,
    selectedFamilyName,
    media,
  ]);

  console.log(family, "fam", userProfile);

  const handleImage = (event) => {
    if (!event.target.files) return;

    if (Array(...event.target.files).length <= 0) {
      infoNotifier("Please add an Image");
      return;
    }
    const file = event.target.files[0];
    setMedia(file); // Store the file object
  };

  const handleSubmit = async () => {
    let familyDetails = family.find(
      (data) => data?.familyName === selectedFamilyName
    );
    console.log(familyDetails, "details");
    setLoading(true);
    const formData = new FormData();
    formData.append(
      "name",
      main === "main" ? formValues.name : formValues.familyName
    );
    formData.append(
      "address",
      main === "main" ? familyDetails?.houseId : familyDetails?.houseId
    );
    formData.append("passport", media);
    formData.append("parent", familyDetails?.parentId);
    formData.append("cardHolder", familyDetails?._id);

    await requestCard(formData)(setPaymentLoading)(setPaymentModal)(navigate)(
      setPaymentData
    );
  };

  const inputStyle = {
    bg: "white",
    rounded: "lg",
    color: "#262636",
  };

  const clearMedia = () => {
    setMedia(null);
  };

  return (
    <SlideFade in={true} offsetX="-2000px">
      <Box minH="100vh">
        <Box shadow="sm">
          <Container>
            <Header goto={ID_CARD} />
          </Container>
        </Box>

        <Container minH="calc(100vh - 100px)">
          <HStack spacing={"20px"}>
            <Text
              color={main === "main" ? "white" : `${cardBg}`}
              fontSize={"20px"}
              bg={main === "main" ? `${COLORS.green}` : "none"}
              p={main === "main" ? "10px 20px" : "0px"}
              rounded={main === "main" ? "3xl" : "0px"}
              onClick={() => {
                setMain("main");
                clearMedia();
              }}
              cursor={"pointer"}
            >
              Main User
            </Text>
            <Text
              // color={cardBg}
              color={main === "family" ? "white" : `${cardBg}`}
              fontSize={"20px"}
              bg={main === "family" ? `${COLORS.green}` : "none"}
              p={main === "family" ? "10px 20px" : "0px"}
              rounded={main === "family" ? "3xl" : "0px"}
              onClick={() => {
                setMain("family");
                clearMedia();
              }}
              cursor={"pointer"}
            >
              Family Members
            </Text>
          </HStack>
          <FormControl h="100%" mt="30px">
            <Stack spacing="20px">
              {main === "main" ? (
                <>
                  <Text color={cardBg} fontSize={"20px"}>
                    Upload passport photo and submit to request for a new ID
                    card
                  </Text>
                  <Input
                    {...inputStyle}
                    placeholder="Name"
                    _placeholder={{ opacity: 1, color: "black" }}
                    border="none"
                    borderBottom="1px solid #ccc"
                    borderRadius="0"
                    value={userProfile?.tenantName}
                    readOnly
                  />
                  <Box>
                    <Text color={cardBg} fontSize={"14px"}>
                      Enter your name as you want it to appear on your card
                    </Text>
                    <Input
                      {...inputStyle}
                      placeholder="Name"
                      name="name"
                      _placeholder={{ opacity: 1, color: "black" }}
                      border="none"
                      borderBottom="1px solid #ccc"
                      borderRadius="0"
                      value={formValues.name}
                      onChange={handleChange}
                    />
                  </Box>
                </>
              ) : (
                <>
                  <Text color={cardBg} fontSize={"20px"}>
                    Choose family member to request a new ID card
                  </Text>

                  <Select
                    {...inputStyle}
                    placeholder="Select family"
                    _placeholder={{ opacity: 1, color: "black" }}
                    border="none"
                    color="green"
                    borderBottom="1px solid #ccc"
                    borderRadius="0"
                    onChange={(e) => setSelectedFamilyName(e.target.value)}
                  >
                    {family?.map((member, index) => (
                      <option key={index} value={member?.familyName}>
                        {member?.familyName}
                      </option>
                    ))}
                  </Select>
                  <Box>
                    <Text color={cardBg} fontSize={"14px"}>
                      Enter family name as you want it to appear on your card
                    </Text>
                    <Input
                      {...inputStyle}
                      placeholder="Name"
                      name="familyName"
                      _placeholder={{ opacity: 1, color: "black" }}
                      border="none"
                      borderBottom="1px solid #ccc"
                      borderRadius="0"
                      value={formValues.familyName}
                      onChange={handleChange}
                    />
                  </Box>
                </>
              )}
            </Stack>

            <Stack>
              <Box mt={"50px"}>
                <FormLabel {...formLabelStyles}>
                  <HStack cursor="pointer">
                    <Text color={cardBg} fontWeight="500">
                      Upload passport photo
                    </Text>
                    <AiOutlineCloudUpload size={"1.5rem"} color={cardBg} />
                  </HStack>
                  <Text color={cardBg} fontSize="13px">
                    Ensure that the photo is clear, taken in a bright place and
                    your face is facing front, and fully visible.
                  </Text>
                  <Input
                    type="file"
                    display="none"
                    id="media"
                    onChange={handleImage}
                  />
                </FormLabel>
              </Box>
              {media && (
                <Img
                  w="70px"
                  h="70px"
                  m="2"
                  borderRadius={"5px"}
                  src={URL.createObjectURL(media)}
                  alt="Passport"
                />
              )}
            </Stack>
            <HStack py="15px" justify="space-between" gap="1.5rem">
              <Stack
                {...inputStyle}
                textAlign="center"
                bg="#c7ebe2"
                px="15px"
                w="150px"
              >
                <Text as="small">Service Charge</Text>
                <Text fontSize="0.9rem" fontWeight="700" m="0 !important">
                  &#8358;{serviceCharge}
                </Text>
              </Stack>
              <ConfirmCardPayment
                allowToOpen={allowToOpen}
                buttonProps={{
                  size: "md",
                  _hover: { background: `${COLORS.green}` },
                  _focus: { background: `${COLORS.green}` },

                  bg: `${COLORS.green}`,
                  // isLoading: loading,
                  p: "10px",
                  borderRadius: "10px",
                  color: "white",
                  w: "150px",
                  textAlign: "center",
                  btnText: "Pay",
                  isDisabled: payBtnDisabled,
                }}
                btnTitle="Pay"
                addCloseModalBtn={true}
              >
                <ConfirmPaymentComponent
                  userProfile={userProfile}
                  userId={userId}
                  paymentLoading={paymentLoading}
                  family={family}
                  setPaymentData={setPaymentData}
                  setPaymentModal={setPaymentModal}
                />
              </ConfirmCardPayment>
            </HStack>
            <PaystackModal
              paymentData={paymentData}
              paymentModal={paymentModal}
              setPaymentModal={setPaymentModal}
              onFinish={() => {
                axiosInstance
                  .post("/wallet/verify-payment", {
                    userId,
                    reference: paymentData?.reference,
                  })
                  .then(() => {
                    successNotifier("Payment Successful");
                    //send id card reques
                    handleSubmit();
                  })
                  .catch(() => errorNotifier("Payment not Successful"));
              }}
              onClose={onClose}
            />
          </FormControl>
        </Container>
        <Footer />
      </Box>
    </SlideFade>
  );
};

export default RequestId;

export const ConfirmPaymentComponent = ({
  paymentLoading,
  userId,
  family,
  userProfile,
  setPaymentData,
  setPaymentModal,
  onClose,
}) => {
  const cardBg = useColorModeValue(COLORS.primary, COLORS.white);

  return (
    <Box color={cardBg}>
      <Text fontWeight="bold" mb="4" fontSize={"1.5em"}>
        Confirm Submission
      </Text>
      <Text mb="4">Are you sure you want to submit this request?</Text>
      <PrimaryButton
        size="md"
        isLoading={paymentLoading}
        btnText="Confirm"
        onClick={() => {
          axiosInstance
            .post("/wallet/initialize-payment", {
              amount: 3000,
              tenantName: userProfile?.tenantName,
              email: userProfile?.email,
              userId,
            })
            .then((res) => {
              const paymentInfo = res?.data?.result?.data;

              setPaymentData(paymentInfo);

              onClose();

              setPaymentModal(true);

              console.log(res, "{{}}}}");
            });
        }}
      />
    </Box>
  );
};
