import { Box, Flex, Img, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { PROTECTED_PATHS } from "../../../app/constants";
import boyImage from "../../../assets/boyImage.jpg";
import { dynamicPathhandler } from "../../../components/utils/dynamicPathHandler";

const OfferWithPrice = ({ advert }) => {
  const navigate = useNavigate();
  const { TOP_PRODUCT_SCREEN } = PROTECTED_PATHS;
  const parentPath = dynamicPathhandler(TOP_PRODUCT_SCREEN);

  return (
    <Flex
      my="10px !important"
      bg="#fff"
      shadow="md"
      rounded="2xl"
      p={"5px"}
      cursor="pointer"
      // w="100%"
      // gap={"2.5rem"}
      justify="center"
      onClick={() => navigate(`${parentPath}/${advert?._id}`)}
    >
      <Box>
        <Img
          w={["120px"]}
          maxW="120px"
          h={["120px"]}
          objectFit="cover"
          rounded="lg"
          src={advert?.imageUrl[0] || boyImage}
          alt="news-image"
        />
        <Text pt="5px" color={"green"} fontSize="14px" fontWeight="bold">
          &#8358; {advert?.price?.toLocaleString()}
        </Text>
        <Text color="#7A7A7A" fontSize="14px" fontWeight="bold" noOfLines={1}>
          {advert?.itemName}
        </Text>
      </Box>
    </Flex>
  );
};

export default OfferWithPrice;
// import { HStack, Img, Stack, Text } from '@chakra-ui/react';
// import { useNavigate } from 'react-router-dom';
// import { PROTECTED_PATHS } from '../../../app/constants';
// import boyImage from '../../../assets/boyImage.jpg';
// import { dynamicPathhandler } from '../../../components/utils/dynamicPathHandler';

// const OfferWithPrice = ({ advert }) => {
//   const navigate = useNavigate();
//   const { TOP_PRODUCT_SCREEN } = PROTECTED_PATHS;
//   const parentPath = dynamicPathhandler(TOP_PRODUCT_SCREEN);

//   return (
//     <HStack
//       my='10px !important'
//       bg='#fff'
//       shadow='md'
//       borderRadius='5px'
//       pr='1.5rem'
//       cursor='pointer'
//       h='105px'
//       onClick={() => navigate(`${parentPath}/${advert?._id}`)}
//     >
//       <Img
//         w={['120px']}
//         maxW='120px'
//         h={['100px']}
//         objectFit='cover'
//         borderRadius='5px'
//         src={advert?.imageUrl[0] || boyImage}
//         alt='news-image'
//       />
//       <Stack spacing={'3'} color='#7A7A7A'>
//         <Text fontWeight='bold'>{advert?.itemName}</Text>
//         <Text m='0 !important' fontSize='12px'>
//           {advert?.itemDescription}
//         </Text>

//         <HStack justify='space-between'>
//           <Text fontWeight='bold' fontSize='14px'>
//             &#8358; {advert?.price}
//           </Text>
//           <Text>see more</Text>
//         </HStack>
//       </Stack>
//     </HStack>
//   );
// };

// export default OfferWithPrice;
