import {
  Box,
  Button,
  Collapse,
  Flex,
  SlideFade,
  Stack,
  Text,
  Img,
  useColorModeValue,
  Image,
} from "@chakra-ui/react";
import { BsBellFill, BsThreeDotsVertical } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import {
  getBanners,
  getEstateDue,
  getEstateDues,
  getEvent,
  getManagerAnnouncement,
} from "./service";
import { AuthContext } from "../../context/authContext";
import FullPageLoader from "../../components/FullPageLoader";
// import EstateDueCard from "./components/EstateDueCard";
import Container from "../../components/Container";
import Header from "../../components/Header";
import Footer from "../../components/footer";
import { PROTECTED_PATHS } from "../../app/constants";
import SingleNewsCard from "./components/SingleNewsCard";
import HeadSeemore from "../../components/HeadSeemore";
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { v4 } from "uuid";
import { transition } from "../..";
import { COLORS } from "../../constants/colors";
import hero from "./hero.png";
import { IoMdPersonAdd } from "react-icons/io";
import { UserProfileContext } from "../../context/userProfileContext";
import { getGuests } from "../guest-history/service";
import dayjs from "dayjs";
import { Popover } from "../../components/Popover";
import { getOutstandingBill } from "../billing-analysis/services";

const MyEstate = () => {
  const { userProfile } = useContext(UserProfileContext);
  const navigate = useNavigate();
  const pageBg = useColorModeValue(COLORS.bgGrey, COLORS.darkModeBg);
  const transBg = useColorModeValue(COLORS.green, COLORS.chartbg);
  const cardBg = useColorModeValue(COLORS.primary, COLORS.white);
  const heroBg = useColorModeValue(COLORS.primary, COLORS.black);
  const pendCardBg = useColorModeValue(COLORS.white, COLORS.white);
  const pendTextBg = useColorModeValue(COLORS.white, COLORS.green);
  const appTextColor = useColorModeValue(COLORS.white, COLORS.grey);
  const [estateDue, setEstateDue] = useState([]);
  const [estateDues, setEstateDues] = useState([]);
  const [events, setEvents] = useState([]);
  const [guests, setGuests] = useState([]);
  const [banners, setBanners] = useState([]);
  const [outstandingBills, setOutstandingBills] = useState([]);
  const { userInfo } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const { userId } = userInfo;
  const [announcements, setAnnouncements] = useState([]);
  const { INVITE_GUEST, PENDING_BILLS, GUEST_LIST } = PROTECTED_PATHS;

  useEffect(() => {
    getEstateDue(userId, setEstateDue, setLoading);
    getEstateDues(setLoading, setEstateDues, userId);
    getEvent(userId, setLoading, setEvents);
    getManagerAnnouncement(setLoading, userId, setAnnouncements);
    getGuests(userId, setLoading, setGuests);
    getBanners(setLoading, userId, setBanners);
    getOutstandingBill(userId, setLoading, setOutstandingBills);
  }, [userId]);

  console.log(outstandingBills, "estateDue:", estateDue);

  return loading ? (
    <FullPageLoader />
  ) : (
    <SlideFade in={true} offsetY="500px" {...transition}>
      <Stack pb="15vh" w={"100%"} overflowX={"hidden"}>
        <Stack bg={pageBg} my="20px">
          <Container>
            <Header menu />
            <Text fontWeight={"bold"} fontSize={"20px"} color={cardBg}>
              Welcome {userProfile?.tenantName}
            </Text>
            <Text color={cardBg}>How may we be of service to you today?</Text>
          </Container>
        </Stack>
        <Container>
          <Box>
            {banners.length > 0 ? (
              <CarouselProvider
                infinite={true}
                visibleSlides={1}
                dragEnabled={false}
                isPlaying={true}
                naturalSlideWidth={120}
                naturalSlideHeight={55}
                totalSlides={banners?.length}
              >
                <Slider>
                  {banners &&
                    banners?.map((b) => (
                      <Slide key={v4()}>
                        <Img {...flasSaleImageStyles} src={b?.imageUrl} />
                      </Slide>
                    ))}
                </Slider>
              </CarouselProvider>
            ) : (
              <Flex bg={COLORS.lightGreen} borderRadius="10px">
                <Flex>
                  <Text
                    fontSize="20px"
                    maxW={"65%"}
                    p="20px"
                    fontWeight="600"
                    color={heroBg}
                  >
                    Good Estate Management Happy Family
                  </Text>
                  <Img w="40%" rounded="lg" src={hero} alt="Hero image" />
                </Flex>
              </Flex>
            )}
          </Box>
        </Container>
        <Container>
          <Flex justify="flex-end">
            <Flex
              bg={cardBg}
              borderLeftRadius="30px"
              borderRightRadius="10px"
              mt="30px"
              p="15px"
              justify="flex-end"
              w="60%"
            >
              <Flex
                w="100%"
                justify="space-between"
                align="center"
                cursor="pointer"
                onClick={() => navigate(INVITE_GUEST)}
              >
                <Box p={"10px"} rounded={"full"} bg={"#c5dbd6"}>
                  <IoMdPersonAdd size={"1.5rem"} color={COLORS.green} />
                </Box>
                <Stack cursor="pointer">
                  <Text color={appTextColor} fontStyle="italic">
                    Click here to
                  </Text>
                  <Text
                    color={appTextColor}
                    fontWeight={"bold"}
                    fontSize="1rem"
                  >
                    Invite Guest
                  </Text>
                </Stack>
              </Flex>
            </Flex>
          </Flex>
        </Container>

        <Container>
          <Flex mt="30px" justify={"space-between"}>
            <Text fontSize="1rem" color={cardBg}>
              Pending Bills
            </Text>
            <Text
              color={COLORS.green}
              cursor={"pointer"}
              onClick={() =>
                navigate(PENDING_BILLS, { state: outstandingBills })
              }
            >
              See All
            </Text>
          </Flex>
          <Box py="15px">
            {outstandingBills && outstandingBills.length > 0 ? (
              outstandingBills.slice(0, 1).map((datum) => (
                <Box
                  key={datum.id} // Assuming there's an id field for each bill
                  shadow="sm"
                  bg={transBg}
                  p={["16px", "20px"]}
                  borderRadius="10px"
                >
                  <Flex justify="space-between" align="center">
                    <Text color={pendCardBg} fontWeight="600" fontSize="15px">
                      {datum?.dueType}
                    </Text>
                    <Text color={pendTextBg} fontWeight="600" fontSize="15px">
                      NGN {datum?.remainingAmount}
                    </Text>
                  </Flex>
                  <Text color={pendCardBg} fontSize="12px">
                    Due Date: {datum.date || "--"}
                  </Text>
                </Box>
              ))
            ) : (
              <Flex
                rounded="lg"
                bg={transBg}
                justify="center"
                align="center"
                h="100px"
              >
                <Text textAlign="center" fontSize="18px" color={pendCardBg}>
                  No pending bills
                </Text>
              </Flex>
            )}
          </Box>
        </Container>

        <Container>
          <Text color={cardBg} fontSize="1rem" pb="10px">
            Announcements
          </Text>
          <Flex
            bg={cardBg}
            justify="space-between"
            align="center"
            p="20px"
            borderRadius="10px"
          >
            <Box flex={1} pl="1rem" textAlign="center">
              <BsBellFill size={"1.5rem"} color="#FC7067" />
            </Box>
            <Stack flex={5}>
              <Flex gap="0.5rem" align="center">
                <Stack>
                  <Text color={appTextColor} fontSize="12px" fontWeight="600">
                    From the Manager's desk
                  </Text>
                  {announcements?.slice(0, 1)?.map((announcement) => (
                    <AnouncementText
                      key={announcement._id}
                      announcement={announcement}
                      color={appTextColor}
                    />
                  ))}
                </Stack>
              </Flex>
            </Stack>
          </Flex>
        </Container>
        <Container>
          <Stack mt="5">
            <HeadSeemore head="News/Events" color={cardBg} />
            {events.length < 1 || !events ? (
              <Flex
                rounded={"lg"}
                bg={cardBg}
                justify="center"
                align="center"
                h="100px"
              >
                <Text textAlign={"center"} fontSize="18px" color={appTextColor}>
                  No news/events now
                </Text>
              </Flex>
            ) : (
              <Box p={"17px 0px 17px 17px"} rounded={"lg"}>
                <CarouselProvider
                  infinite={true}
                  visibleSlides={2}
                  dragEnabled={true}
                  isPlaying={true}
                  naturalSlideWidth={100}
                  naturalSlideHeight={100}
                  totalSlides={events?.length}
                >
                  <Slider>
                    {events?.map((event, index) => (
                      <Slide key={v4()} index={index}>
                        <SingleNewsCard {...event} />
                      </Slide>
                    ))}
                  </Slider>
                </CarouselProvider>
              </Box>
            )}
          </Stack>
        </Container>

        <Container mt={"20px !important"}>
          <Flex align="center" justify="space-between">
            <Text color={cardBg} fontSize="1rem" pb="10px">
              Guest Log
            </Text>
            <Text
              color={COLORS.green}
              cursor={"pointer"}
              onClick={() => navigate(GUEST_LIST)}
            >
              See All
            </Text>
          </Flex>
          {guests && guests.length > 0 ? (
            guests.slice(0, 3).map((datum) => (
              <Box
                bg={cardBg}
                p="10px 20px"
                borderRadius="10px"
                mb={"20px"}
                key={datum.id}
              >
                <Flex
                  align="flex-start"
                  justify={"space-between"}
                  color={appTextColor}
                >
                  <Stack mt="10px">
                    <Text fontSize="12px" fontWeight="600">
                      {datum?.name}
                    </Text>
                    <Text fontSize="12px">
                      Last day of visit{" "}
                      {dayjs(datum?.endDate).format("DD/MMM/YYYY")}
                    </Text>
                    <Text fontSize="12px">
                      {datum?.allowCompany ? "With Company" : "Without Company"}
                    </Text>
                  </Stack>
                  {/* <Box alignSelf={"flex-end"}>
          <Popover data={datum} />
        </Box> */}
                </Flex>
              </Box>
            ))
          ) : (
            <Flex
              rounded={"lg"}
              bg={cardBg}
              justify="center"
              align="center"
              h="100px"
              color={appTextColor}
            >
              <Text textAlign={"center"} fontSize="18px">
                No guest now
              </Text>
            </Flex>
          )}
        </Container>
        <Footer />
      </Stack>
    </SlideFade>
  );
};

export default MyEstate;

const AnouncementText = ({ announcement, color }) => {
  const pendTextBg = useColorModeValue(COLORS.white, COLORS.green);
  const [show, setShow] = useState(false);

  return (
    <>
      <Collapse startingHeight={18} in={show} style={{ color: color, maxWidth: "70%" }}>
        {announcement.body}
      </Collapse>
      <Button
        variant="link"
        _focus={{ border: "none" }}
        w="70px"
        size="xs"
        onClick={() => setShow(!show)}
        mt="1rem"
        color={pendTextBg}
        ml="-5px !important"
      >
        Show {show ? "Less" : "More"}
      </Button>
    </>
  );
};

const flasSaleImageStyles = {
  w: "100%",
  h: "150px",
  objectFit: "cover",
  borderRadius: "10px",
  alt: "flash sales",
};

export const dummy = [
  {
    type: "service charge",
    amount: 3000,
    date: "24th october"
  },
  {
    type: "charge",
    amount: 4000,
    date: "24th october"
  }
]