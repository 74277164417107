import {
  Box,
  FormControl,
  FormLabel,
  Img,
  Input,
  SlideFade,
  Stack,
} from '@chakra-ui/react';
import Container from '../../../../components/Container';
import { PrimaryButton } from '../../../../components/CustomButton';
import Header from '../../../../components/Header';
import { Success } from '../../../../components/Success';
import mtn from '../../../../assets/MTN.svg';
import { inputBorder } from '../../../post advert/components/data';
import { PROTECTED_PATHS } from '../../../../app/constants';
import useAirtimePurchase from './useAirtimePurchase';
import { COLORS } from '../../../../constants/colors';

const MTNAirtimePayment = () => {
  const { MY_WALLET } = PROTECTED_PATHS;

  const {
    handleAirtimePurshase,
    amount,
    setAmount,
    mobileNumber,
    setMobileNumber,
    loading,
    success,
  } = useAirtimePurchase();

  return success ? (
    <Success />
  ) : (
    <SlideFade in={true} offsetX="-2000px">
      <Stack>
        <Box shadow="sm">
          <Container>
            <Header goto={MY_WALLET} />
          </Container>
        </Box>
        <Container>
          <Stack py="5" color={COLORS.green}>
            <Img py="5" w="130px" src={mtn} alt="mtn" />
            {/* <PaymentForm /> */}

            <FormControl>
              <Stack spacing="0">
                <FormLabel
                  htmlFor="decoder"
                  mb="0 !important"
                  fontSize="14px"
                  pt="5"
                >
                  Phone Number
                </FormLabel>
                <Input
                  {...inputBorder}
                  type="number"
                  placeholder="081555555555"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                />
                <FormLabel htmlFor="amount" mb="0" fontSize="14px" pt="5">
                  Amount
                </FormLabel>
                <Input
                  {...inputBorder}
                  type="number"
                  placeholder="500"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </Stack>
            </FormControl>
          </Stack>
          <PrimaryButton
            isLoading={loading}
            my="6"
            btnText="Make Payment"
            onClick={() => handleAirtimePurshase(1)}
          />
        </Container>
      </Stack>
    </SlideFade>
  );
};

export default MTNAirtimePayment;
