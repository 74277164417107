import {
  Box,
  Center,
  Flex,
  HStack,
  Img,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { v4 } from "uuid";
import { PROTECTED_PATHS } from "../../../app/constants";
import { dynamicPathhandler } from "../../../components/utils/dynamicPathHandler";
import { BsThreeDotsVertical } from "react-icons/bs";
import { COLORS } from "../../../constants/colors";

export const ArchivedProducts = ({ data }) => {
  const { VIEW_ONE_ARCHIEVED_PRODUCT } = PROTECTED_PATHS;
  const parentPath = dynamicPathhandler(VIEW_ONE_ARCHIEVED_PRODUCT);
  const appTextColor = useColorModeValue(COLORS.black, COLORS.white);

  return (
    <Box>
      {data?.length > 0 ? (
        <Stack spacing="5">
          {data?.map((item) => (
            <Link to={`${parentPath}/${item._id}`} key={v4()}>
              <HStack
                key={v4()}
                gap="1rem"
                bg="gray.50"
                shadow="sm"
                p="7px"
                rounded="lg"
              >
                <Img w="75px" h="75px" src={item?.imageUrl[0]} alt="product" />
                <Flex justify="space-between" w="100%">
                  <Stack>
                    <Text fontSize="1rem">{item?.itemName}</Text>
                    <Text fontSize="1.2rem" fontWeight={600}>
                      {item?.price} NGN
                    </Text>
                  </Stack>
                  {/*<BsThreeDotsVertical size={"1.5rem"} />*/}
                </Flex>
              </HStack>
            </Link>
          ))}
        </Stack>
      ) : (
        <Center flexDirection="column" h="70vh" w="100%">
          <Img src="/empty.svg" alt="empty" />
          <Text py="5" fontWeight="500" textAlign="center" color={appTextColor}>
            You do not have any achived product yet
          </Text>
        </Center>
      )}
    </Box>
  );
};
