import { FormControl, FormLabel, Input, Stack } from '@chakra-ui/react';
import Select from 'react-select';
import { inputBorder } from '../../post advert/components/data';

const PaymentForm = ({ setIUCNumber, selectedOption, setSelectedOption, cablePlans }) => {
  return (
    <FormControl>
      <Stack spacing='0'>
        <FormLabel htmlFor='plan' m='0 !important' fontSize='14px'>
          Select Plan
        </FormLabel>
        <Select placeholder='Select a plan' defaultValue={selectedOption} onChange={setSelectedOption} options={cablePlans} />

        <FormLabel htmlFor='decoder' mb='0 !important' fontSize='14px' pt='5'>
          Decoder Number
        </FormLabel>
        <Input {...inputBorder} onChange={(e) => setIUCNumber(e.target.value)} />
        <FormLabel htmlFor='amount' mb='0' fontSize='14px' pt='5'>
          Amount
        </FormLabel>
        <Input {...inputBorder} defaultValue={selectedOption?.companyAmount + selectedOption?.planAmount || ''} />
      </Stack>
    </FormControl>
  );
};

export default PaymentForm;
