import { Flex, HStack, Square, Stack, Text } from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';
import CustomModal from '../../../components/CustomModal';
import FullPageLoader from '../../../components/FullPageLoader';
import { AuthContext } from '../../../context/authContext';
import { getErrandPackages } from '../services/getErrands';
import ConfirmPaymentModal from './ConfirmPaymentModal';
// import { errandSub } from './errandsData';
import { v4 } from 'uuid';

const TypeOfErrands = () => {
  const { userInfo } = useContext(AuthContext);
  const { userId } = userInfo;
  const [errandPackage, setErrandPackage] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getErrandPackages(setLoading, userId, setErrandPackage);
  }, [userId]);

  return loading ? (
    <FullPageLoader />
  ) : (
    <>
      <Text mt='8' mb='2' fontWeight='bold' fontSize='1.2rem'>
        Types of errands
      </Text>
      <HStack justify='center' gap='1rem'>
        {[1, 2, 3].map((_, index) => (
          <Square key={index} px='3' py='1' border='1px solid #FEDEDC'>
            Filling of gas
          </Square>
        ))}
      </HStack>
      <HStack py='5' justify='space-around' gap='1rem'>
        {errandPackage.map((errand, index) => (
          <CustomModal
            key={v4()}
            div
            btnTitle={
              <ErrandPurchase
                index={index}
                numberOfErrands={errand.numberOfErrands}
                packagePrice={errand.packagePrice}
                subscriptionName={errand.subscriptionName}
              />
            }
          >
            <ConfirmPaymentModal
              subscriptionId={errand._id}
              numberOfErrands={errand.numberOfErrands}
              packagePrice={errand.packagePrice}
              subscriptionName={errand.subscriptionName}
            />
          </CustomModal>
        ))}
      </HStack>
    </>
  );
};

export default TypeOfErrands;

const ErrandPurchase = ({ index, numberOfErrands, packagePrice, subscriptionName }) => {
  return (
    <Stack h='120px' bg='#F9F5F5' p='3' borderRadius='4px' textAlign='center' borderTop={`solid 3px ${index % 2 === 0 ? '#9A82B8' : '#F98B88'}`}>
      <Text fontSize='12px' fontWeight='600'>
        {subscriptionName}
      </Text>
      <Text px='1' py='2px' border='1px solid #EFE2FF' fontSize='12px' borderRadius='5px'>
        <b>{numberOfErrands}</b> errands
      </Text>
      <Text fontSize='' fontWeight='500'>
        &#8358; <b>{packagePrice}</b>
      </Text>
      <Flex justify='center' pos='relative' top='0.3em'>
        <BsFillArrowRightCircleFill size={30} />
      </Flex>
    </Stack>
  );
};
