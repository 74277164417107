import { useState } from "react";
import {
  Stack,
  Box,
  useColorModeValue,
  Flex,
  Text,
  Select,
} from "@chakra-ui/react";
import { COLORS } from "../../constants/colors";
import Footer from "../../components/footer";
import FullPageLoader from "../../components/FullPageLoader";
import Header from "../../components/Header";
import Container from "../../components/Container";
import { RiErrorWarningFill } from "react-icons/ri";
import BarChart from "./components/BillsBarChart";
import { BillsPieChart } from "./components/BillsPieChart";
import { useEffect } from "react";
import { getBillAnalysis, getOutstandingBill, getTotalBill } from "./services";
import { useContext } from "react";
import { AuthContext } from "../../context/authContext";

const BillingAnalysis = () => {
  const { userInfo } = useContext(AuthContext);
  const { userId } = userInfo;
  const cardBg = useColorModeValue(COLORS.primary, COLORS.white);
  const bgColor = useColorModeValue(COLORS.primary, COLORS.green);
  const [loading, setLoading] = useState(false);
  const [outstandingBills, setOutstandingBills] = useState([]);
  const [totalBills, setTotalBills] = useState([]);
  const [billAnalysis, setBillAnalysis] = useState([]);
  const [dueType, setDueType] = useState("specific cleaning 1");

  useEffect(() => {
    getOutstandingBill(userId, setLoading, setOutstandingBills);
    getTotalBill(userId, setLoading, setTotalBills);
    getBillAnalysis(userId, dueType, setLoading, setBillAnalysis);
  }, [userId, dueType]);

  const totalBillsWithColor = totalBills.map((item, index) => ({
    ...item,
    color: datum[index].color, // Using the color information from the datum array
  }));

  const handleDueTypeChange = (dueType) => {
    setDueType(dueType);
  };

  console.log(billAnalysis, "total", outstandingBills);
  console.log("billss", totalBillsWithColor, totalBills);
  return loading ? (
    <FullPageLoader />
  ) : (
    <Stack pb="15vh">
      <Box shadow="sm">
        <Container>
          <Header menu />
        </Container>
      </Box>

      <Container>
        <Stack spacing="5">
          <Text pt="50px" fontSize="1.2rem" color={cardBg}>
            Outstanding Bills
          </Text>
          <Flex justify={"space-between"}>
            <Box bg="#E4F3E5" shadow="sm" p="7px" rounded="lg" w={"48%"}>
              <Flex
                bg={"#80bd92"}
                p={"5px"}
                justify="center"
                rounded="md"
                w="40px"
              >
                <RiErrorWarningFill size={"1.5rem"} color={bgColor} />
              </Flex>
              <Text fontSize="17px" fontWeight={600} pt="6px">
                NGN - {outstandingBills[0]?.remainingAmount || 0}
              </Text>
              <Text fontSize="14px">
                {outstandingBills[0]?.dueType || "--"}
              </Text>
            </Box>
            <Box
              bg={COLORS.lightPink}
              shadow="sm"
              p="7px"
              rounded="lg"
              w={"48%"}
            >
              <Flex
                bg="#ebb881"
                p={"5px"}
                justify="center"
                rounded="md"
                w="40px"
              >
                <RiErrorWarningFill size={"1.5rem"} color={"#db5c40"} />
              </Flex>
              <Text fontSize="17px" fontWeight={600} pt="6px">
                NGN - {outstandingBills[1]?.remainingAmount || 0}
              </Text>
              <Text fontSize="14px">
                {outstandingBills[1]?.dueType || "--"}
              </Text>
            </Box>
          </Flex>
        </Stack>
        <Text pt="50px" pb="10px" fontSize="1.2rem" color={cardBg}>
          Bills Analysis
        </Text>
        <Box bg={COLORS.chartbg} w={"100%"} rounded={"lg"} p="20px">
          {!billAnalysis || billAnalysis < 1 ? (
            <Flex
              bg="white"
              shadow="sm"
              justify="center"
              align="center"
              w="100%"
              h="250px"
              rounded="sm"
            >
              <Text fontSize="22px">No data available</Text>
            </Flex>
          ) : (
            <>
              <Flex justify={"flex-end"} mb={"0px"}>
                <Select
                  color={cardBg}
                  w="170px"
                  bg="#ABACC26B"
                  onChange={(e) => handleDueTypeChange(e.target.value)}
                  value={dueType}
                >
                  <option value={"specific cleaning 1"}>
                    Specific Cleaning
                  </option>
                  <option value={"service-charge"}>Service Charge</option>
                  <option value={"security-charge"}>Security Charge</option>
                </Select>
              </Flex>

              <BarChart cardBg={cardBg} data={billAnalysis} dueType={dueType} />
            </>
          )}
        </Box>
        <Text pt="50px" fontSize="1.2rem" color={cardBg}>
          Total Bills in the last 30 Days
        </Text>
        <Flex
          bg={COLORS.chartbg}
          w={"100%"}
          rounded={"lg"}
          px="20px"
          mt={"20px"}
          justify={"space-between"}
          align={"center"}
        >
          {!totalBillsWithColor || totalBillsWithColor < 1 ? (
            <Flex
              bg="white"
              shadow="sm"
              justify="center"
              align="center"
              w="100%"
              h="250px"
              rounded="sm"
            >
              <Text fontSize="22px">No data available</Text>
            </Flex>
          ) : (
            <>
              <Box w={"50%"}>
                <BillsPieChart data={totalBillsWithColor} />
              </Box>
              <Box fontWeight={400} w={"45%"}>
                {totalBillsWithColor?.map((datum, itemIndex) => (
                  <Flex align={"center"} pb={"0px"} key={itemIndex}>
                    <Box
                      h="11px"
                      w="11px"
                      rounded={"sm"}
                      bg={datum?.color}
                      mr="7px"
                    ></Box>
                    <Stack mt="20px" spacing={1} color={cardBg}>
                      <Text noOfLines={1}>{datum?.dueType}</Text>
                      <Text fontWeight={"semibold"}>
                        NGN {datum?.totalAmountPaid}
                      </Text>
                    </Stack>
                  </Flex>
                ))}
              </Box>
            </>
          )}
        </Flex>
      </Container>
      <Footer />
    </Stack>
  );
};

export default BillingAnalysis;

const datum = [
  {
    color: "#116072",
    text: "20,000",
    title: "Electricity",
  },
  {
    color: "#A5C2E7",
    text: "30,000",
    title: "Security Fees",
  },
  {
    color: "#F96400AD",
    text: "40,000",
    title: "Service Charge",
  },
];
