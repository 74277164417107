import http, { AUTH_ROUTES } from '../../../services/api';
import { errorNotifier } from '../../../components/NotificationHandler';
import { timeout } from '../../../components/utils/timeout';
import { PROTECTED_PATHS } from '../../../app/constants';

export const postAdvert = async (setLoading, payload, setSuccess, navigate) => {
  const { MARKET_PLACE } = PROTECTED_PATHS;
  setLoading(true);

  try {
    await http.post(AUTH_ROUTES.POST_ADVERT, payload);

    setSuccess(true);
    timeout(() => {
      setSuccess(false);
      navigate(MARKET_PLACE);
    }, 4000);
  } catch (error) {
    console.log(error.response);
    errorNotifier(error.response.data.result.message);
  } finally {
    setLoading(false);
  }
};

export const getCategories = async (userId, setLoading, setCategories) => {
  setLoading(true);
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_CATEGORIES(userId));

    setCategories(data?.categories);
  } catch (err) {
    console.log(err.response);
  } finally {
    setLoading(false);
  }
};
